// css libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import 'nouislider/dist/nouislider.css';
import 'swiper/css';
import "swiper/css/effect-cards";
import "swiper/css/effect-creative";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import 'swiper/css/manipulation';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import '../node_modules/dropzone/dist/dropzone.css';
import '../node_modules/noty/lib/noty.css';
import '../node_modules/noty/lib/themes/relax.css';

// main css
import '../css/normalize.css';
import '../css/fonts.css';
import '../css/icons.css';
import '../css/variables.css';
import '../css/custom-styles.css';
import '../css/stylesheet.css';
import '../css/important-styles.css';

// polyfills
require('es6-promise').polyfill();

// js libraries
import { Popover, Tooltip } from 'bootstrap';
import 'hammerjs';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Header modules
import AjaxSearch from './modules/Header/AjaxSearch';
import DesktopMenu from './modules/Header/DesktopMenu';
import MobileMenu from './modules/Header/MobileMenu';

// Footer modules
import ScrollTop from './modules/Footer/ScrollTop';
import CheckCookies from './modules/Footer/CheckCookies';

// Home page modules
import Articles from './modules/Home/Articles';
import HomeBanner from './modules/Home/HomeBanner';
import HomeCounterNumbers from './modules/Home/HomeCounterNumbers';
import HomeNews from './modules/Home/HomeNews';
import HomeShareHistory from './modules/Home/HomeShareHistory';
import HomeVideoAbout from './modules/Home/HomeVideoAbout';
import { LatestBestsellers } from './modules/Home/LatestBestsellers';
import PopularCategories from './modules/Home/PopularCategories';
import PopularInteriors from './modules/Home/PopularInteriors';
import WeekSelection from './modules/Home/WeekSelection';

// Catalog modules
import AddMoreProducts from './modules/Catalog/AddMoreProducts';
import AllCategories from './modules/Catalog/AllCategories';
import CatalogProductCard from './modules/Catalog/CatalogProductCard';
import Filter from './modules/Catalog/Filter';
import SortSelect from './modules/Catalog/SortSelect';

// Search page
import Search from './modules/Search/Search';

// Product page
import Product from './modules/Product/Product';

// Other modules
import ArticlesPage from './modules/ArticlesPage';
import Compare from './modules/Compare';
import { ContactForm } from './modules/ContactForm';
import Favourites from './modules/Favourites';
import InputMask from './modules/InputMask';
import ProductCard from './modules/ProductCard';
import Subscribes from './modules/Subscribes';
import YandexGoals from './modules/Services/YandexGoals';

// About page
import Carusel from './modules/About/Carusel';

// Cities
import CityManagerInit from './modules/Cities/CityManager';

// Cart order page
import CartOrder from './modules/CartOrder/CartOrder';

// Utils
import { ScrollToSection } from './modules/utils/ScrollToSection';

import CountDate from './modules/Other/CountDate';


document.addEventListener("DOMContentLoaded", function () {

	// Add tooltips
	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

	// Add popovers
	const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
	const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl));

	// Header
	MobileMenu();
	AjaxSearch();
	DesktopMenu();

	//Fooer
	ScrollTop();
	CheckCookies();


	// Other
	ContactForm();
	Subscribes();
	Compare();
	new Favourites();
	ProductCard();
	InputMask();

	//Home page
	HomeBanner();
	PopularCategories();
	WeekSelection();
	LatestBestsellers();
	PopularInteriors();
	Articles();
	HomeCounterNumbers();
	HomeShareHistory();
	HomeNews();
	HomeVideoAbout();

	// Catalog
	AllCategories();
	SortSelect();
	CatalogProductCard();
	Filter();
	AddMoreProducts();


	// Article page
	ArticlesPage();

	//Search page
	Search();

	// Product page
	Product();

	// Cities
	CityManagerInit();

	// Cart order page
	CartOrder();

	// About
	Carusel();

	// Utils
	ScrollToSection();

	YandexGoals();

	CountDate();

});