import Swiper from 'swiper';

const Carusel = () => {

		new Swiper(".aboutSliderCarusel", {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			grabCursor: true,
			resizeReInit: true,
			centeredSlides: true,
			breakpoints: {
				576: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 30
				}
			}
		});
}

export default Carusel;