import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

const PopularInteriors = () => {
		document.querySelectorAll('.popularInteriorsSlider').forEach(slider => {
			const swiper = new Swiper(slider, {
					modules: [Pagination],
					slidesPerView: 1,
					speed: 800,
					grabCursor: true,
					spaceBetween: 30,
					rewind: true,
					pagination: {
						el: ".popular-interiors-pagination",
						clickable: true
					},
					breakpoints: {
						992: {
							slidesPerView: 2,
						}
					}
			});
		});
}

export default PopularInteriors;