const CustomSelect = (idSelect, {...onClickFunctions}) => {
		const select = document.querySelector(idSelect),
					idSelectClean = idSelect.replace(/#/g, ''),
					idCustom = 'customSelect_' + idSelectClean;

		// Changes in the default selection and create custom select
		const classes = select.getAttribute('class'),
					placeholder = select.getAttribute('placeholder');

		let template = '<div id="' + idCustom +'" class="' + classes + '">';

		template += `<span class="custom-select-trigger">${placeholder}</span>`;
		template += '<div class="custom-options custom-scrollbar">';
		select.querySelectorAll('option').forEach(option => {
				const class_ = option.getAttribute('class'),
							value_ = option.getAttribute('value');

				template += `<span class="custom-option ${(class_) ? class_ : ''}" data-value="${(value_) ? value_ : ''}">${option.innerHTML}</span>`;
		});
		template += '</div></div>';

		// Create wrapper
		const wrapper = document.createElement('div');
		wrapper.className = 'custom-select-wrapper';
		select.parentNode.insertBefore(wrapper, select);
		select.style.display = 'none';
		wrapper.appendChild(select);
		wrapper.insertAdjacentHTML('beforeend', template);

		// Changes custom select
		const customSelect = document.querySelector(`#${idCustom}`);

		customSelect.querySelectorAll(".custom-select-trigger").forEach(function(trigger) {
			
			trigger.addEventListener('click', function(event) {
				event.currentTarget.parentNode.classList.toggle("opened");
				event.target.classList.toggle("opened");
				event.stopPropagation();
			});
		});

		// Changes when selected
		const optionSelected = (element) => {
			const select = element.closest('.custom-select-wrapper').querySelector('select');

			const val = element.dataset.value.replace(/\u00A0/g, '');
			const text = element.textContent.replace(/\u00A0/g, '');

			select.value = val;
	
			const customOptions = element.closest('.custom-options');
			customOptions.querySelectorAll('.custom-option').forEach(function(option) {
					option.classList.remove('selection');
			});
			element.classList.add('selection');
			element.closest('.custom-select').classList.remove('opened');
			element.closest('.custom-select').querySelector('.custom-select-trigger').textContent = text;
	}
	

		customSelect.querySelectorAll(".custom-option").forEach(function(option) {

			option.addEventListener('click', function(event) {
				if (!event.target.classList.contains('no-link')) {
						
					optionSelected(event.target);

					// Launching others functions when clicked
					if (onClickFunctions) {
						Object.values(onClickFunctions).forEach(func => func(event.target));
					}
				}
			});

			if (option.classList.contains('selected')) {
				optionSelected(option);
			}

		});

}

export default CustomSelect;