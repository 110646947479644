import Swiper from 'swiper';
import { Navigation, Thumbs, EffectFade, FreeMode, Pagination } from 'swiper/modules';
import { Fancybox } from "@fancyapps/ui";

const ProductSlider = () => {

	// Fancybox play
	Fancybox.bind('[data-fancybox="product_gallery"]');
	
  const thumbs = new Swiper(".productCardSliderThumbs", {
		modules: [Navigation, Thumbs],
		speed: 700,
		lazy: true,
    spaceBetween: 12,
    slidesPerView: 5,
		autoHeight: true,
    watchSlidesProgress: true,
		grabCursor: true,
		direction: 'vertical',
    navigation: {
      nextEl: ".swiper-button-next.product-main-slider-arrows",
      prevEl: ".swiper-button-prev.product-main-slider-arrows",
    },
		breakpoints: {
			1200: {
				spaceBetween: 22,
			}
		}
  });

  const productCardSlider = new Swiper(".productCardSlider", {
		modules: [Thumbs, EffectFade, Pagination],
		speed: 800,
		lazy: true,
    spaceBetween: 40,
		grabCursor: true,
    thumbs: {
      swiper: thumbs,
    },
		pagination: {
			el: ".product-slider-pag",
			clickable: true
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
    rewind: true,
  });

	const productCardSlideButtons = new Swiper(".productCardSlideButtons", {
		modules: [FreeMode],
    spaceBetween: 15,
		freeMode: {
			enabled: true,
		},
		centeredSlides: false,
		enabled: true,
		slidesPerView: 'auto',
		breakpoints: {
			1200: {
				enabled: false
			}
		},
		on: {
			slideChange: function () {
				const wrapper = this.wrapperEl;
				const transformValue = getComputedStyle(wrapper).transform;
				const translateX = parseFloat(transformValue.split(',')[4]);
	
				const limit = 45;
				if (translateX < -limit) {
					wrapper.style.transform = 'translate3d(' + -limit + 'px, 0px, 0px)';
				}
			}
		}
  });


};

export default ProductSlider;
