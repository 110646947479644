import { Modal } from "bootstrap";

const LoadingArModels = () => {

	window.bootstrap = {...window.bootstrap, Modal};

	const htmlModal = (id, src, title) => {
		return `
				<div class="modal fade" id="${id}" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-lg" style="min-width: 50%;">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">${title}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<iframe src="${src}" frameborder="0" style="width: 100%; height: 600px;"></iframe>
						</div>
					</div>
				</div>
			</div>
		`
}

const openModal = (event) => {
	event.preventDefault();

	const id = event.currentTarget.getAttribute('data-modal_id'),
				src = event.currentTarget.getAttribute('data-modal_src'),
				title = event.currentTarget.getAttribute('data-modal_title');

	document.body.insertAdjacentHTML('beforeend', htmlModal(id, src, title));

	const modal = new bootstrap.Modal(`#${id}`);

	modal.show();

}

	const btnLoadModel = document.querySelector('#btn-load-ar-model'),
				btnLoadInteriorModel = document.querySelector('#btn-load-interior-ar-model');

	btnLoadInteriorModel?.addEventListener('click', event => openModal(event));

	btnLoadModel?.addEventListener('click', event => openModal(event));

}

export default LoadingArModels;