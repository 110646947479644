const Spinner = (element, removeSpinner = false) => {
		const spinner = Object.assign(document.createElement('div'), {
					className: 'd-flex justify-content-center spinner-custom',
					innerHTML: '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="visually-hidden">Loading...</span></div>'
				});
		
		element.style.opacity = !removeSpinner ? '0' : '1';
		!removeSpinner ? element.after(spinner) : element.nextElementSibling.remove();
}

export default Spinner;