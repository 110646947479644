export const ScrollToSection = () => {
	const items = document.querySelectorAll('.link-scroll-section')

		if (items)
		items.forEach(function(link) {
			link.addEventListener('click', function(e) {
				e.preventDefault();
	
				const sectionName = link.getAttribute('href');

				if (sectionName || sectionName !== null)
				document.querySelector(sectionName).scrollIntoView({ block: "center", behavior: "smooth" });
			});
		});

}