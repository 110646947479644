// js libraries
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const LatestBestsellers = () => {
	document.querySelectorAll('.homeProductCardSlider').forEach(slider => {
		const swiper = new Swiper(slider, {
				modules: [Navigation],
				slidesPerView: 'auto',
				spaceBetween: 15,
				centeredSlides: true,
				rewind: true,
				initialSlide: 2,
				grabCursor: true,
				navigation: {
					nextEl: slider.closest('.container-product-card').querySelector('.swiper-button-next.product-card-arrow'),
					prevEl: slider.closest('.container-product-card').querySelector('.swiper-button-prev.product-card-arrow'),
				},
				breakpoints: {
					768: {
						slidesPerView: 'auto',
						centeredSlides: true,
						spaceBetween: 20,
						initialSlide: 2,
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 25,
						centeredSlides: false,
						initialSlide: 0,
					},
					1400: {
						slidesPerView: 4,
						spaceBetween: 25,
						centeredSlides: false,
						initialSlide: 0,
					}
				},
				on: {
					init: function () {
						document.querySelectorAll('.product-card-img-wrapper')?.forEach(element => {
							const HAMMER = new Hammer(element);

							HAMMER.on('panstart', function () {
									swiper.allowTouchMove = false;
							});

							HAMMER.on('panend', function () {
									swiper.allowTouchMove = true;
							});
						});

					},
				},
		});

	});
}

export {LatestBestsellers};