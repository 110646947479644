import { Fancybox } from "@fancyapps/ui";
import { Modal } from "bootstrap";
import { Dropzone } from 'dropzone';
import Noty from 'noty';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import CustomSelect from '../CustomSelect';
import Captcha from '../Services/Captcha';
import { LoadContent } from '../Services/LoadContent';
import LoadingElements from '../Services/LoadingElements';
import ReviewsSendStars from './ReviewsSendStars';

const Reviews = () => {

	window.bootstrap = {...window.bootstrap, Modal};

	const loading = new LoadingElements();

	// Fancybox play all images

	Fancybox.bind('[data-fancybox="product_reviews_gallery"]', {
		Images: {
			content: (_ref, slide) => {
				const json = document.querySelector('[data-reviews_product]').innerHTML,
							data = JSON.parse(json),
							currentData = data.filter(e => e.p_review_id === slide.review_id)[0];

				if (currentData) {
					const html = `
					<div class="product-reviews-slide">
						<div class="product-reviews-slider-wr row custom-scrollbar">
								<div class="col-md-8">
									<img 
									 src="${slide.src}" 
									 title="${slide.img_title}" 
									 alt="${slide.img_alt}" 
									 data-src="${slide.src}" 
									 class="lazyload" 
									 loading="lazy" 
									 srcset="${slide.src}?width=100 100w, ${slide.src}?width=200 200w, ${slide.src}?width=400 400w, ${slide.src}?width=600 600w, ${slide.src}?width=800 800w" 
									 sizes="(max-width: 800px) 100vw, 50vw" 
									 style="aspect-ratio: 16/9" 
									 decoding="async" 
									 />
								</div>
								<div class="col-md-4">
									<div class="reviews-gallery-content-review custom-scrollbar">` +
										(currentData.name ? `
											<div class="d-flex gap-2 reviews-gallery-content-user">
											<svg width="53" height="48" viewBox="0 0 53 48" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="0.981445" y="0.109863" width="51.4507" height="47.2875" rx="14" fill="#E7E4DF"/>
											<path d="M38.0402 36.5037C38.0402 32.5917 32.9661 29.4203 26.7069 29.4203C20.4476 29.4203 15.3735 32.5917 15.3735 36.5037M26.7069 25.1703C22.7949 25.1703 19.6235 21.999 19.6235 18.087C19.6235 14.175 22.7949 11.0037 26.7069 11.0037C30.6189 11.0037 33.7902 14.175 33.7902 18.087C33.7902 21.999 30.6189 25.1703 26.7069 25.1703Z" stroke="#2C3638" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
												<div class="d-flex flex-column">
													<div class="reviews-gallery-content-review-name">${currentData.name}</div>
													<span class="reviews-gallery-content-review-date">${currentData.date_added}</span>
												</div>
											</div>` : '') +
										(currentData.good ? `
										<div class="reviews-gallery-content-review-text">
											<div>Достоинства</div>
											<p>${currentData.good}</p>
										</div>
										` : '') +
										(currentData.bad ? `
										<div class="reviews-gallery-content-review-text">
											<div>Недостатки</div>
											<p>${currentData.bad}</p>
										</div>
										` : '') +
										(currentData.text ? `
										<div class="reviews-gallery-content-review-text">
											<div>Комментарий</div>
											<p>${currentData.text}</p>
										</div>
										` : '') +
							`	</div>
								</div>
							</div>
						</div>
						`;

					return html;

				} else {
					return `
						<img 
							src="${slide.src}" 
							title="${slide.img_title}" 
							alt="${slide.img_alt}" 
							data-src="${slide.src}" 
							class="lazyload" 
							loading="lazy" 
							srcset="${slide.src}?width=100 100w, ${slide.src}?width=200 200w, ${slide.src}?width=400 400w, ${slide.src}?width=600 600w, ${slide.src}?width=800 800w" 
							sizes="(max-width: 800px) 100vw, 50vw" 
							style="aspect-ratio: 16/9" 
							decoding="async" 
						/>
					`;
				}
			}
		}
	}); 
	

	// All images slider
	const productReviewsSlider = new Swiper(".productReviewsSlider", {
		modules: [Navigation],
		slidesPerView: 'auto',
		speed: 600,
		centeredSlides: false,
		spaceBetween: 20,
		rewind: true,
		navigation: {
			nextEl: ".swiper-button-next.product-reviews-slider-arrow",
			prevEl: ".swiper-button-prev.product-reviews-slider-arrow",
		},
		breakpoints: {
			992: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1400: {
				slidesPerView: 5,
			}
		}
	});

		// reviews item current slider images
		const productReviewCurrentSliders = () => {
			document.querySelectorAll('.productReviewCurrentSlider')?.forEach(e => {
					const swiper = new Swiper(e, {
							modules: [Navigation],
							slidesPerView: 'auto',
							speed: 600,
							centeredSlides: false,
							spaceBetween: 20,
							rewind: true,
							navigation: {
									nextEl: ".swiper-button-next.product-reviews-slider-arrow",
									prevEl: ".swiper-button-prev.product-reviews-slider-arrow",
							},
							breakpoints: {
									992: {
											slidesPerView: 2,
									},
									1200: {
											slidesPerView: 3,
									},
									1400: {
											slidesPerView: 4,
									}
							}
					});

					Fancybox.bind(`[data-fancybox="product_review_gallery${e.getAttribute('data-review_id')}"]`);

			});
		}


	// custom sort
	const sortReviews = (element) => {
		const value = element.getAttribute('data-value');
		const productId = document.getElementById('product-product').getAttribute('data-product_id');
		const hasImage = document.getElementById('showReviewsOnlyImg').checked;

		loading.onChildrenLoadingPlaceholder('#productReviewsWrapper', '.product-review-block', true);
		LoadContent({
			parentSelector: '#productReviewsWrapper',
			url: `index.php?route=extension/module/p_review/p_review&product_id=${productId}&${value}&has_image=${hasImage}`,
			elementSelector: '.product-review-block',
			cleanParent: true
		})
		.then(() => {
			productReviewCurrentSliders();
		})
		.finally(() => {
			loading.onChildrenLoadingPlaceholder('#productReviewsWrapper', '.product-review-block', false);
		});
	}

	if (document.getElementById('reviews_sort')) CustomSelect('#reviews_sort', {sortReviews});

	//Only photo check reviews
	document.querySelector('#showReviewsOnlyImg')?.addEventListener('change', (event) => {
			const productId = document.getElementById('product-product').getAttribute('data-product_id'),
						sort = document.querySelector('#customSelect_reviews_sort .custom-option.selection'),
						hasImage = event.target.checked;

			loading.onChildrenLoadingPlaceholder('#productReviewsWrapper', '.product-review-block', true);
			LoadContent({
				parentSelector: '#productReviewsWrapper',
				url: `index.php?route=extension/module/p_review/p_review&product_id=${productId}${sort !== null ? '&' + sort.getAttribute('data-value') : ''}&has_image=${hasImage}`,
				elementSelector: '.product-review-block',
				cleanParent: true
			})
			.then(() => {
				productReviewCurrentSliders();
			})
			.finally(() => {
				loading.onChildrenLoadingPlaceholder('#productReviewsWrapper', '.product-review-block', false);
			});
	});

	productReviewCurrentSliders();


	/* 
	***********************
	Reviews contact form (rewrite to class)
	**********************
	*/


	/* drag and drop */
	try {
			const dropzone = new Dropzone("#productReviewSendForm .dropzone", {
				url: '/',
				dictDefaultMessage: '<span class="icon-add-img"></span> Перетащите файлы сюда или нажмите для выбора файлов',
				dictFallbackMessage: 'Ваш браузер не поддерживает функцию Drag and Drop',
				dictInvalidFileType: 'Неверный тип файла. Поддерживаемые типы файлов: jpg, png',
				dictFallbackText: 'Пожалуйста, используйте резервную форму ниже для загрузки файлов.',
				dictFileTooBig: 'Файл слишком большой ({{filesize}} МБ). Максимальный размер файла: {{maxFilesize}} МБ.',
				dictResponseError: 'Ошибка сервера (код: {{statusCode}}). Перезагрузите страницу или попробуйте позже.',
				dictMaxFilesExceeded: 'Превышено количество загружаемых файлов.',
				dictRemoveFile: 'Удаление файла',
				dictUploadCanceled: 'Загрузка отменена.',
				dictCancelUpload: 'Отменить загрузку',
				dictCancelUploadConfirmation: 'Вы уверены, что хотите отменить загрузку?',
				uploadMultiple: true,
				maxFilesize: 4,
				paramName: 'image',
				maxThumbnailFilesize: 4,
				maxFiles: 6,
				acceptFiles: 'image/jpeg,image/png,image/jpg',
				acceptedMimeTypes: 'image/jpeg,image/png,image/jpg',
			});

			dropzone.on("addedfile", file => {
				const deleteButton = Dropzone.createElement('<div class="dz-remove-file"><span class="icon-close"></span></div>');

				file.previewElement.appendChild(deleteButton);

				deleteButton.addEventListener("click", function(e) {
					e.preventDefault();
					e.stopPropagation();
					dropzone.removeFile(file);
				});

			});


		const sendStarRating = new ReviewsSendStars("#ratingStarsWrapper");
		const productReviewSendModal = new bootstrap.Modal('#productReviewSend');

		const resetForm = (form) => {
			productReviewSendModal.hide();
			form.reset();
			form.querySelectorAll('.form__label').forEach(e => {
				e.style.cssText = '';
				e.querySelector('.cf-label-name').style.cssText = '';
			});
		}

		const sendSuccess = (form, successModal) => {
			resetForm(form);
			dropzone.removeAllFiles();
			successModal._element.querySelector('.success-title').textContent = 'Вы успешно оставили отзыв. Спасибо!';
			successModal.show();
			setTimeout(() => {
				successModal.hide();
			}, 3500);
		}

		const loadingModal = (modal, hide) => {
			if (!hide) {
				modal.classList.add('block-modal');
				modal.querySelector('.modal-content').insertAdjacentHTML('afterBegin', '<div class="loading-wrapper"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div><div class="loading-text">Происходит отправка, пожалуйста подождите.</div></div>');
			} else {
				modal.querySelector('.loading-wrapper')?.remove();
				modal.classList.remove('block-modal');
			}
		}

		const getRemainingTime = (getDate) => {
			const dateString = getDate;

			const date = new Date(dateString);

			date.setHours(date.getHours() + 24);

			const currentDate = new Date();
			const targetTimeZone = 'Europe/Moscow';
			const options = {
				timeZone: targetTimeZone,
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			};

			const formattedDate = currentDate.toLocaleString('en-US', options);

			const timeDifference = date.getTime() - new Date(formattedDate).getTime();

			const hours = Math.floor(timeDifference / (1000 * 60 * 60));
			const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

			return { hours, minutes };
		};

		document.querySelector('#productReviewSendForm')?.addEventListener('submit', async (event) => {
			event.preventDefault();

			const successModal = new bootstrap.Modal('#successMessage');
			const form = event.target;
			const dataForm = new FormData(form);
			const captcha = new Captcha('/catalog/controller/extension/captcha/validate.php', '/catalog/controller/extension/captcha/captcha.php');
			const currentModal = form.closest('.modal');

			dropzone.getAcceptedFiles().forEach((file) => {
				dataForm.append(`image[]`, file);
			});

			try {
				await captcha.onAddCaptcha();
				loadingModal(currentModal, false);

				const response = await fetch(`index.php?route=extension/module/p_review/write&product_id=${document.getElementById('product-product').getAttribute('data-product_id')}`, {
					method: 'POST',
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
					body: dataForm
				});

				if (response.ok) {
					const data = await response.json();

					if (data.captcha_valid && data.success && !data.error) {

						loadingModal(currentModal, true);
						sendSuccess(form, successModal);

					} else if (data.reviewsLimit) {

						loadingModal(currentModal, true);
						const remainingTime = getRemainingTime(data.block_time);
						throw new Error(`Превышен лимит отзывов. Лимит сбросится через ${remainingTime.hours} часов ${remainingTime.minutes} минут`);

					} else {
						throw new Error('Произошла ошибка. Пожалуйста исправьте ошибки в форме или перезагрузите страницу');
					}
				} else {
					throw new Error('Возникла ошибка сервера, повторите попытку позже.');
				}
			} catch (error) {
				if (error.message === 'Ошибка в captcha') {
					new Noty({
						theme: 'relax',
						type: 'error',
						timeout: 3500,
						text: 'Ошибка в captcha',
					}).show();
					return;
				}

				resetForm(form);
				dropzone.removeAllFiles(true);
				loadingModal(currentModal, true);
				console.error(error);
				new Noty({
					theme: 'relax',
					type: 'error',
					timeout: 3500,
					text: error.message,
				}).show();
			}
		});
	} catch (error) {
		console.log(error);
	}


}

export default Reviews;