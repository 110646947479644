import CustomSelect from "../CustomSelect";

const Search = () => {

	const btnSearch = document.querySelector('#button-search');

	if (btnSearch) {

		if (document.querySelector('#search_page_select_category')) {
			CustomSelect('#search_page_select_category', {});
		}

		btnSearch.addEventListener('click', (event) => {
			
			let url = 'index.php?route=product/search';
			const search = document.querySelector('#input-search-page'),
						categoryId = document.querySelector('#search_page_select_category').value,
						subCategory = document.querySelector('#search-page-sub-category'),
						filterDescription = document.querySelector('#search-page-filter-description');

			if (search.value) {
				url += `&search=${encodeURIComponent(search.value)}`;
			}

			if (categoryId > 0) {
				url += `&category_id=${encodeURIComponent(categoryId)}`;
			}

			if (subCategory.checked) {
				url += '&sub_category=true';
			}

			if (filterDescription.checked) {
				url += '&description=true';
			}

			if (search.value.length > 0) {
				search.setCustomValidity('');
				window.location = url;
			} else {
				search.setCustomValidity('Пожалуйста введите поисковой запрос.');
				search.reportValidity();
			}
	});

	document.addEventListener('keydown', (event) => {
		if (!event.target.querySelector('#button-search') && event.key == 'Enter') {
			btnSearch.click();
		}
	});

	document.querySelector('#search_page_select_category').addEventListener('change', (event) => {
		if (event.target.value == 0) {
			event.currentTarget.dataset.disabled = true;
		} else {
			event.currentTarget.dataset.disabled = false;
		}
	});

	}


}

export default Search;