import { Toast } from 'bootstrap';
class Favourites {
	constructor() {
		this.buttons = document.querySelectorAll('.favorite-add');
		this.products = JSON.parse(localStorage.getItem("dataProductStorage")) || [];
		this.totalElements = document.querySelectorAll('.favourites-total-num');
		this.canvasBody = document.querySelector('#header_favourites_canvas .offcanvas-body');
		this.successMessage = null;

		window.bootstrap = {...window.bootstrap, Toast};

		this.init();
	}

	async getProduct(product_id) {
		try {
			const response = await fetch('index.php?route=common/favourites/getProduct', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: `product_id=${product_id}`
			});

			if (response.ok) {
				const data = await response.json();
				if (!data.error && data.success) {
					return data.data;
				} else {
					throw new Error();
				}
			} else {
				throw new Error(`${response.statusText}\r\n${await response.text()}`);
			}
		} catch (error) {
			console.log(`Error: ${error.message}`);
		}
	}

	onChangeProductLocalStorage(product_id) {
		if (this.products.includes(product_id)) {
			this.products = this.products.filter(e => e !== product_id);
		} else {
			this.products.push(product_id);
		}
		localStorage.setItem("dataProductStorage", JSON.stringify(this.products));
	}

	onChangeCanvasProduct(body, data, startup = false) {
		const { product_id, image, name, sku, model, price, price_old, link = `/index.php?route=product_id=${product_id}` } = data;
		const canvasProduct = document.querySelector(`.cart-canvas-item[data-product_id="${product_id}"]`);

		if (this.products.includes(product_id) && !canvasProduct) {
			if (!startup) {
				this.onShowToast(product_id, `<div>Товар ${model} <a href="${link}">${name}</a> добавлен в избранное!</div><span class="icon-check"></span>`);
			}

			body.insertAdjacentHTML('beforeend', `
			<div class="cart-canvas-item d-flex justify-content-between align-items-center" data-product_id="${product_id}">
				<a href="${link}"><img src="/image/${image}" alt="${name}" title="${name}"></a>

				<div class="cart-canvas-item-text-wr">
					<div class="cart-canvas-item-title-wr d-flex align-items-center">
						<a href="${link}"><h4>${sku} ${name}</h4></a>
						<span class="icon-delete cart-product-remove favorite-remove"></span>
					</div>
					<p>${model}</p>
					<div class="cart-canvas-item-end-wr d-flex justify-content-start align-items-center w-100 gap-2">
						<div class="cart-canvas-item-price">${price}</div>
						${(parseInt(price_old) !== 0 && price_old) ? `<div class="cart-canvas-item-price cart-canvas-item-price-old">${price_old}</div>` : ''}
					</div>
				</div>
			</div>
		`);
		
		} else {
			if (!startup) {
				this.onShowToast(product_id, `<div>Товар ${model} <a href="${link}">${name}</a> удален из избранного!</div><span class="icon-close"></span>`);
			}

			canvasProduct.remove();
		}
	}

	onChangeButtonColor(button, id) {
		if (button) {
			if (this.products.includes(id)) {
				button.classList.add('active');
			} else {
				button.classList.remove('active');
			}
		}
	}

	onChangeTotal() {
		this.totalElements.forEach(e => {
				e.textContent = this.products.length;
				if (this.products.length === 0) {
					e.classList.add('empty');
				} else {
					e.classList.remove('empty');
				}
		});
	}

	onCheckEmptyBody() {
    if (this.canvasBody.childElementCount === 0) {
        const emptyElement = document.createElement('div');
        emptyElement.className = 'cart-canvas-empty';
        emptyElement.innerHTML = 'Список избранного пуст';
        this.canvasBody.appendChild(emptyElement);
    } else {
        const emptyElement = this.canvasBody.querySelector('.cart-canvas-empty');
        if (emptyElement) {
            emptyElement.remove();
        }
    }
	}

	onShowToast(productId, message) {
			document.querySelectorAll('.toast.custom-add-toast')?.forEach(e => {
					e.remove();
			});

			document.querySelector('.toast-container').insertAdjacentHTML('beforeend', `
				<div id="successFavourites${productId}" class="toast custom-add-toast" role="alert" aria-live="assertive" aria-atomic="true">
				<div class="toast-header">
				<svg width="86" height="18" viewBox="0 0 86 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.74899 17.9285C13.5513 17.9285 17.4443 14.0377 17.4443 9.23817C17.4443 4.43864 13.5513 0.547852 8.74899 0.547852C3.94672 0.547852 0.0537109 4.43864 0.0537109 9.23817C0.0537109 14.0377 3.94672 17.9285 8.74899 17.9285Z" fill="#BBA0AF"/>
				<path d="M31.3632 17.9285C36.1655 17.9285 40.0585 14.0377 40.0585 9.23817C40.0585 4.43864 36.1655 0.547852 31.3632 0.547852C26.561 0.547852 22.668 4.43864 22.668 9.23817C22.668 14.0377 26.561 17.9285 31.3632 17.9285Z" fill="#869EA4"/>
				<path d="M53.9599 17.9285C58.7622 17.9285 62.6552 14.0377 62.6552 9.23817C62.6552 4.43864 58.7622 0.547852 53.9599 0.547852C49.1577 0.547852 45.2646 4.43864 45.2646 9.23817C45.2646 14.0377 49.1577 17.9285 53.9599 17.9285Z" fill="#D0B6A5"/>
				<path d="M76.5683 17.9285C81.3706 17.9285 85.2636 14.0377 85.2636 9.23817C85.2636 4.43864 81.3706 0.547852 76.5683 0.547852C71.7661 0.547852 67.873 4.43864 67.873 9.23817C67.873 14.0377 71.7661 17.9285 76.5683 17.9285Z" fill="#E5E2DD"/>
				</svg>
				
						<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div class="toast-body">${message}</div>
				</div>
		`);

		const toast = document.querySelector(`#successFavourites${productId}`);

		if (toast) {
			const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
			toastBootstrap.show();
		}

		setTimeout(() => {
			toast.remove();
		}, 7000);
	}


	init() {
		this.buttons.forEach(e => {
			const id = e.getAttribute('data-product_id');

			if (this.products.includes(id)) {
				this.onChangeButtonColor(e, id);
			}

			e.addEventListener('click', event => {
				const button = event.currentTarget;
				const product_id = button.getAttribute('data-product_id');

				this.onChangeProductLocalStorage(product_id);
				this.onChangeButtonColor(button, product_id);
				this.onChangeTotal();
				this.getProduct(product_id).then(data => {
					this.onChangeCanvasProduct(this.canvasBody, data);
					this.onCheckEmptyBody();
				});
				
			});
		});

		this.canvasBody.addEventListener('click', event => {
			const removeButton = event.target;
			if (removeButton.classList.contains('favorite-remove')) {
					const cartCanvasItem = removeButton.closest('.cart-canvas-item');
					if (cartCanvasItem) {
							const product_id = cartCanvasItem.getAttribute('data-product_id');
							this.onChangeProductLocalStorage(product_id);
							this.onChangeButtonColor(document.querySelector(`.favorite-add[data-product_id="${product_id}"]`), product_id);
							this.onChangeTotal();
							cartCanvasItem.remove();
							this.onCheckEmptyBody();
					}
			}
		});

		this.onChangeTotal();
		this.onCheckEmptyBody();
		this.products.forEach(e => {
			this.getProduct(e).then(data => {
				this.onChangeCanvasProduct(this.canvasBody, data, true);
				this.onCheckEmptyBody();
			});
		});
	}
}

export default Favourites;
