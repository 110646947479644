const ScrollTop = function () {
  const btnUp = {
    el: document.querySelector('.scroll-btn-up'),

    show() {
      this.el.classList.remove('scroll-btn-up_hide');
    },

    hide() {
      this.el.classList.add('scroll-btn-up_hide');
    },

    addEventListener() {
      let scrollingTimer;

      const handleScroll = () => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;

        if (scrollY > 400) {
          this.show();
        } else {
          this.hide();
        }

        clearTimeout(scrollingTimer);
        scrollingTimer = setTimeout(() => {
          this.hide();
        }, 5000);
      };

      window.addEventListener('scroll', handleScroll);

      document.querySelector('.scroll-btn-up').addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    },
  };

  btnUp.addEventListener();
};

export default ScrollTop;
