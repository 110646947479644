import { Modal } from 'bootstrap';
import Noty from 'noty';
import isEmail from 'validator/lib/isEmail';
import Captcha from './Services/Captcha';

const Subscribes = () => {

	window.bootstrap = {...window.bootstrap, Modal};

	const formIds = ['subscribeForm', 'subscribeForm2'];

	formIds.forEach(formId => {
		const form = document.querySelector(`#${formId}`);
		if (!form) return;

		const submitButton = form.querySelector('input[type="submit"]');
		const handleClick = event => {
			handleFormSubmit(form);
			submitButton.removeEventListener('click', handleClick);
			};

		submitButton.addEventListener('click', handleClick);
	});
	
	function handleFormSubmit(form){
		if (form) {
			console.log('1');
			form.addEventListener('submit', async (event) => {
				event.preventDefault();

				let emailInput = form.querySelector("#email_cf_subscribe");
				const email = emailInput.value;
				console.log(email);
				
				// Setting isEmail
				const verified_email = (isEmail(email, {
					allow_display_name: false, 
					require_display_name: false, 
					allow_utf8_local_part: false, 
					require_tld: true,
					ignore_max_length: false, 
					allow_ip_domain: false, 
					domain_specific_validation: true, 
					blacklisted_chars: '', 
					host_blacklist: []
				}));

				console.log('2');
				console.log(verified_email);

				if (verified_email === true) {

					console.log('3');

					const modal = new bootstrap.Modal('#subscribeSuccess');

					const modal2 = new bootstrap.Modal('#subscribeFailed');

					const captcha = new Captcha('/catalog/controller/extension/captcha/validate.php', '/catalog/controller/extension/captcha/captcha.php');

					try {
						await captcha.onAddCaptcha();
						
						const response = await fetch('index.php?route=mail/mail_send/subscribe', {
							method: 'POST',
							body: new FormData(form)
						});

						if (response.ok) {
								const data = await response.json();

								if (data.captcha && data.result === 'success') {
									form.reset();
									form.querySelector('.form__label').style.cssText = '';
									form.querySelector('.cf-label-name').style.cssText = '';
		
									modal.show();
									setTimeout(() => {
										document.querySelector('.email-notification input').checked = true;
									}, 700);
									setTimeout(() => {
										modal.hide();
									}, 3500);
								} else {
									throw new Error('Заявка не отправлена. Попробуйте позже');
								}
						} else {
							throw new Error(`Server error. Error code: ${data.status}`);
						}

					} catch (error) {
						new Noty({
							theme: 'relax',
							type: 'error',
							timeout: 3000,
							text: error.message,
						}).show();
						console.error(error);
					}

				} else {

					console.log('4');
					emailInput.setCustomValidity('Введен некоректный почтовый адрес');
					emailInput.reportValidity();
					
				}
				emailInput = form.querySelector("#email_cf_subscribe");
				emailInput.addEventListener('input', () => {
					emailInput.setCustomValidity('');
					emailInput.reportValidity();
				});
			});
		}
	};
}

export default Subscribes;