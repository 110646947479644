const CartOrder = () => {

// Simplecheckout init
const startSimpleInterval_0 = window.setInterval(function(){
	if (typeof jQuery !== 'undefined' && typeof Simplecheckout === "function" && jQuery.isReady) {
			window.clearInterval(startSimpleInterval_0);

			const simplecheckout_0 = new Simplecheckout({
					mainRoute: "checkout/simplecheckout",
					additionalParams: "",
					additionalPath: "",
					mainUrl: "index.php?route=checkout/simplecheckout&group=0",
					mainContainer: "#simplecheckout_form_0",
					currentTheme: "",
					loginBoxBefore: "",
					displayProceedText: 0,
					scrollToError: 1,
					scrollToPaymentForm: 0,
					notificationDefault: 1,
					notificationToasts: 0,
					notificationCheckForm: 0,
					notificationCheckFormText: "",
					useAutocomplete: 1,
					useStorage: 0,
					popup: 0,
					agreementCheckboxStep: '0',
					enableAutoReloaingOfPaymentFrom: 0,
					javascriptCallback: function() {try{  } catch (e) {console.log(e)}},
					stepButtons: [],
					menuType: 1,
					languageCode: "ru-ru"
			});

			if (typeof toastr !== 'undefined') {
					toastr.options.positionClass = "toast-top-right";
					toastr.options.timeOut = "5000";
					toastr.options.progressBar = true;
			}

			$(document).ajaxComplete(function(e, xhr, settings) {
					if (settings.url.indexOf("route=module/cart&remove") > 0 || (settings.url.indexOf("route=module/cart") > 0 && settings.type == "POST") || settings.url.indexOf("route=checkout/cart/add") > 0 || settings.url.indexOf("route=checkout/cart/remove") > 0) {
							window.resetSimpleQuantity = true;
							simplecheckout_0.reloadAll();
					}
			});

			$(document).ajaxSend(function(e, xhr, settings) {
					if (settings.url.indexOf("checkout/simplecheckout&group") > 0 && typeof window.resetSimpleQuantity !== "undefined" && window.resetSimpleQuantity) {
							settings.data = settings.data.replace(/quantity.+?&/g,"");
							window.resetSimpleQuantity = false;
					}
			});

			simplecheckout_0.init();
			
			}
	},0);


}

export default CartOrder;