import Noty from 'noty';
import Compare from "../Compare";
import Favourites from "../Favourites";
import ProductCard from "../ProductCard";
import Spinner from "../Services/Spinner";
import CatalogProductCard from './CatalogProductCard';

const AddMoreProducts = () => {

		const button = document.querySelector('#category_load_more'),
					limit = button?.getAttribute('data-limit'),
					total = button?.getAttribute('data-total'),
					productsWrapper = document.querySelector('.catalog-products-wrapper .row');

		let offset = +limit;

		button?.addEventListener('click', () => {
				const url = new URL(document.location),
							searchParams = new URLSearchParams(url.search);

			searchParams.set('offset', offset);
			searchParams.set('limit', limit);

			const newUrl = url.origin + url.pathname + '?' + searchParams.toString();

				Spinner(button);

				fetch(newUrl)
				.then(response => {
					if (response.ok) {
						return response.text();
					} else {
						throw new Error('Ошибка на сервере: ' + response.statusText);
					}
				})
				.then(data => {
					const parser = new DOMParser();
					const htmlDocument = parser.parseFromString(data, 'text/html');
					const wrapper = htmlDocument.querySelector('.catalog-products-wrapper .row');
	
					Array.from(wrapper.querySelectorAll('.catalog-product-item')).forEach(e => {
						productsWrapper?.insertAdjacentHTML('beforeend', e.outerHTML);
					});
	
					Spinner(button, true);

					offset += parseInt(limit);

					history.replaceState({}, '', newUrl);

					if (offset >= parseInt(total)) {
						button.remove();
					}

					new Favourites();
					Compare();
					ProductCard();
					CatalogProductCard();
				})
				.catch(error => {
					new Noty({
						theme: 'relax',
						type: 'error',
						timeout: 3000,
						text: error.message,
					}).show();
					console.error(error);
				})

		});

}

export default AddMoreProducts;