import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const RecommendedProducts = () => {

	const productsRecommendedCarusel = new Swiper(".productsRecommendedCarusel", {
		modules: [Navigation],
		speed: 600,
		slidesPerView: 1,
		centeredSlides: true,
    spaceBetween: 30,
		grabCursor: true,
    rewind: true,
		navigation: {
      nextEl: ".swiper-button-next.product-recommended-arrows",
      prevEl: ".swiper-button-prev.product-recommended-arrows",
    },
		breakpoints: {
			400: {
				slidesPerView: 1.3,
				centeredSlides: true,
				spaceBetween: 30,
			},
			576: {
				spaceBetween: 30,
				slidesPerView: 2,
				centeredSlides: false
			},
			992: {
				spaceBetween: 30,
				slidesPerView: 3,
				centeredSlides: false
			},
			1200: {
				spaceBetween: 20,
				slidesPerView: 4,
				centeredSlides: false
			},
			1400: {
				spaceBetween: 30,
				slidesPerView: 4,
				centeredSlides: false
			}
		}
  });

}

export default RecommendedProducts;