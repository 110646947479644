import { Modal } from 'bootstrap';

const HideShowModals = {
	
	hideModal(id) {

		window.bootstrap = {...window.bootstrap, Modal};

		const modal_el  = document.querySelector('#'+id);
		const modal_obj = bootstrap.Modal.getInstance(modal_el);

		if (modal_obj == null){
			 return;
		}

	 modal_obj.hide();
	},

	showModal(id) {

		window.bootstrap = {...window.bootstrap, Modal};

		const modal_el  = document.querySelector('#'+id);
		let   modal_obj = bootstrap.Modal.getInstance(modal_el);

		if (modal_obj ==  null){
			modal_obj = new bootstrap.Modal(modal_el, {
				backdrop: 'static'
			});
	}

	modal_obj.show();
	}

}

export default HideShowModals;