import { Toast, Tab } from "bootstrap";

class Configuration {

	accordionItemsHide = (accordionItems, accordionBtns) => {
		accordionItems.forEach(e => {
			if (e.classList.contains('show')) {
				e.classList.remove('show');
			}
		});

		accordionBtns.forEach(e => {
			if (!e.classList.contains('collapsed')) {
				e.classList.add('collapsed');
			}
		});
	}

	removeAllActive = (clothes) => {
		clothes?.forEach(e => {
			e.classList.remove('active');
		});
	}

	addCurrentActive = (element) => {
		element.classList.add('active');
	}

	rollUpToastConfig = (modalClothConfig) => {
		if (window.innerWidth <= 1200) {
			modalClothConfig?.classList.add('roll-up');
		}
	}

	configFixName = (clothConfigs) => {
		clothConfigs?.forEach(e => {
			const input = e.querySelector('input');
			input.style.width = (input.value.length + 1) + 'ch';
		});
	}

	onClothDescriptionModalFix = () => {
		document.querySelectorAll('.product-cloth-description-modal')?.forEach(modal => {
			modal.addEventListener('show.bs.modal', e => {
				setTimeout(() => {
					const backdrop = document.querySelector('.modal-backdrop');
					if (backdrop) backdrop.style.cssText = 'z-index: 999';
				}, 500);
			})
		});
	}

	onImageClothClick = (accordionBtns, clothCat, isClothOpen = false) => {
		accordionBtns.forEach(btn => {
			if (clothCat.currentTarget.getAttribute('data-elementid') == btn.getAttribute('data-elementid')) {
				btn.click();
			}
		});

		if (isClothOpen) {
			document.querySelectorAll('.product-cloth-nav-btn')?.forEach(cloth => {
				if (clothCat.currentTarget.getAttribute('data-clothid') == cloth.getAttribute('data-elementid'))
					cloth.click();
			});
		}
	}

	getPriceByCategory = (data, url) => {
		return Promise.resolve(fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Ошибка на сервере.');
				}
			})
			.then(data => {
				//console.log(data)
				if (!data.error) {
					return data.data;
				} else {
					throw new Error('Ошибка на клиенте');
				}
			})
			.catch(error => {
				console.error(error);
			}));
	}


	onHandleCloth = (element, clothConfigs, clothes) => {
		const capitalizeFirstLetter = (string) => {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

		const activeName = element.getAttribute('data-name'),
			activeParentName = element.getAttribute('data-parentname'),
			activeImage = element.getAttribute('data-img'),
			categoryId = element.getAttribute('data-category_id'),
			clothId = element.getAttribute('data-elementid'),
			materialId = element.getAttribute('data-parentid'),
			productId = document.querySelector('#product-product').getAttribute('data-product_id'),
			priceElements = document.querySelectorAll('.product-price'),
			priceOldElements = document.querySelectorAll('.product-price-old'),
			saleElements = document.querySelectorAll('.product-sale'),
			fullName = (activeName.indexOf(activeParentName) >= 0) ? capitalizeFirstLetter(activeName) : capitalizeFirstLetter(activeParentName) + ', ' + activeName,
			options = document.querySelectorAll('.product-config-value');

		const optionParams = ((options) && Array.from(options).some((e) => e.getAttribute('data-change_price') === '1')) ? {
			option_id: options[0].getAttribute('data-global_option_id'),
			option_value: options[0].textContent,
			/* 			multi_options: multiOptions?.split("#").map(part => {
							const match = part.match(/(.*?)\[(\d+)\]/);
							return { name: match[1], total: parseInt(match[2]) };
					}) */
			multi_options: options[0].getAttribute('data-option_values')
		} : null;

		this.getPriceByCategory({
			product_id: productId,
			cloth_id: clothId,
			material_category_id: materialId,
			category_id: categoryId,
			...optionParams
		}, '/index.php?route=product/product/getPriceByClothes')
			.then(data => {
				priceElements?.forEach(e => {
					e.textContent = data.price;
					document.querySelector(`.product-clothes-item[data-id-attribute="${categoryId}"] .product-category-price`).textContent = data.price;
				});

				priceOldElements?.forEach(e => {
					e.textContent = data.price_old;
					document.querySelector(`.product-clothes-item[data-id-attribute="${categoryId}"] .product-category-price-old`).textContent = data.price_old;
				});

				saleElements?.forEach(e => {
					if (data.sale !== null) {
						e.style.display = 'flex';
					} else {
						e.style.display = 'none';
					}

					e.textContent = `-${data.sale}%`;
				});

			});

		this.removeAllActive(clothes);
		this.addCurrentActive(element);

		clothConfigs?.forEach(e => {
			const input = e.querySelector('input'),
				image = e.querySelector('img');

			input.style.width = (fullName.length + 1) + 'ch';
			input.value = fullName;
			input.setAttribute('data-category_id', categoryId);
			input.setAttribute('data-cloth_img_id', clothId);
			input.setAttribute('data-cloth_id', materialId);
			input.setAttribute('data-cloth_img', activeImage);
			if (image) {
				image.setAttribute('src', activeImage);
				image.setAttribute('alt', activeName);
				image.setAttribute('title', activeName);
			}
		});

	}

}

const ProductConfigurationResult = () => {
	window.bootstrap = { ...window.bootstrap, Toast, Tab };

	const configuration = new Configuration(),
		toast = new bootstrap.Toast('#productClothConfig'),
		canvas = document.getElementById('product_clothes_canvas'),
		accordionItems = document.querySelectorAll('.product-clothes-item-collapse'),
		accordionBtns = document.querySelectorAll('.product-clothes-item .accordion-button'),
		accordions = document.querySelectorAll('.product-clothes-item'),
		categoryBtns = document.querySelectorAll('.product-clothes-btns-item-name'),
		clothes = document.querySelectorAll('.cloth-img-item'),
		clothConfigs = document.querySelectorAll('.product-cloth-config-item'),
		modalClothConfig = document.getElementById('productClothConfig'),
		clothSale = document.querySelector('.cloth-category-btn-sale.product-cloth-nav-btn'),
		clothImgSale = document.querySelector('.cloth-img-sale');

	// Hide and show elements
	/* 	categoryBtns?.forEach(e => {
			e.addEventListener('click', (e) => configuration.onImageClothClick(accordionBtns, e));
	
			const total = e.querySelector('.product-clothes-btn-total');
	
			const matchingAccordions = [...accordions].filter(elem => elem.getAttribute('data-id-attribute') == e.getAttribute('data-elementid'));
			const totalCount = matchingAccordions.reduce((count, accordion) => count + accordion.querySelectorAll('.product-clothes-tab li').length, 0);
	
			total.textContent = `${totalCount.toString()} коллекций`;
		}); */

	function declineWord(number, forms) {
		const cases = [2, 0, 1, 1, 1, 2];
		return forms[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
	}

	categoryBtns?.forEach(e => {
		e.addEventListener('click', (e) => configuration.onImageClothClick(accordionBtns, e));

		const total = e.querySelector('.product-clothes-btn-total');

		const matchingAccordions = [...accordions].filter(elem => elem.getAttribute('data-id-attribute') == e.getAttribute('data-elementid'));
		const totalCount = matchingAccordions.reduce((count, accordion) => count + accordion.querySelectorAll('.product-clothes-tab li').length, 0);

		total.textContent = `${totalCount.toString()} ${declineWord(totalCount, ['коллекция', 'коллекции', 'коллекций'])}`;
	});


	canvas?.addEventListener('show.bs.offcanvas', () => {
		configuration.accordionItemsHide(accordionItems, accordionBtns);
		toast.show();
	});

	canvas?.addEventListener('hide.bs.offcanvas', () => {
		toast.hide();
		configuration.accordionItemsHide(accordionItems, accordionBtns);
	});

	// Clothes

	configuration.onClothDescriptionModalFix();
	configuration.rollUpToastConfig(modalClothConfig);
	configuration.configFixName(clothConfigs);

	if (clothSale || clothImgSale) {
		new bootstrap.Tab(clothSale).show();
		configuration.onHandleCloth(clothImgSale, clothConfigs, clothes);
	}

	clothes?.forEach(cloth => {
		cloth.addEventListener('click', event => {
			configuration.onHandleCloth(event.currentTarget, clothConfigs, clothes);
		});
	});

	document.querySelectorAll('.product-cloth-nav-btn')?.forEach(nav => {
		nav.addEventListener('click', navCurrent => {
			[...clothes].filter(cloth => navCurrent.currentTarget.dataset.elementid === cloth.dataset.parentid)[0].click();
		});
	});

	document.querySelector('.product-cloth-config-roll-up')?.addEventListener('click', () => {
		modalClothConfig?.classList.toggle('roll-up');
	});


	document.querySelectorAll('.product-clothes-btns-image-btn')?.forEach(e => {
		e.addEventListener('click', event => {
			configuration.onImageClothClick(accordionBtns, event, true)
		})
	});

}

export { ProductConfigurationResult, Configuration };