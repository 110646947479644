import Swiper from 'swiper';
import { Scrollbar } from 'swiper/modules';

const PopularCategories = () => {

		// Mobile slider
		if (window.innerWidth <= 992) {
			const popularCategorySlider = new Swiper(".popularCategory", {
				modules: [Scrollbar],
				slidesPerView: "auto",
				centeredSlides: true,
				allowTouchMove: true,
				spaceBetween: 20,
				rewind: true,
				scrollbar: {
					el: '.swiper-scrollbar.popular-category-slider-scrollbar',
					draggable: true,
				},
				breakpoints: {
					480: {
						spaceBetween: 30,
					}
				}
			});
		}
}

export default PopularCategories;