import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

const HomeShareHistory = () => {
	const sliderShareHistory = new Swiper(".sliderShareHistory", {
		modules: [Autoplay],
		slidesPerView: 1,
		spaceBetween: 20,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false
		},
		speed: 800,
		loop: true,
		grabCursor: true,
		resizeReInit: true,
		centeredSlides: true,
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 40
			}
		},
	});
}

export default HomeShareHistory;