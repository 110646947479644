const ProductCard = () => {
		const product_cards = document.querySelectorAll('.product-card-img-wrapper');


		for (let i = 0; i < product_cards.length; i++) {
			const items = product_cards[i].querySelectorAll('.pch-item').length,
						item = product_cards[i].querySelectorAll('.pch-item'),
						width_item = Math.round(100/items);

			for (let k = 0; k < item.length; k++) {
				item[k].style.cssText = `width: ${width_item}%; left: ${width_item*k}%`
			}

			const SLIDER = product_cards[i];
			const SLIDES = product_cards[i].querySelectorAll('.pc-img-item');

			const HAMMER = new Hammer(SLIDER);
			const SLIDERSIZE = 100;
			const SENSITIVITY = 15;

			let panIsRunning = false;
			let activeIndex = 0;
			
			HAMMER.on('pan', $event => {
				panIsRunning = true;
				const panDistance = SLIDERSIZE / SLIDES.length * $event.deltaX / SLIDER.clientWidth;
				if ($event.isFinal) {
					if (panDistance <= -(SENSITIVITY / SLIDES.length)) {
						goToSlide(activeIndex + 1);
					} else if (panDistance >= SENSITIVITY / SLIDES.length) {
						goToSlide(activeIndex - 1);
					} else {
						goToSlide(activeIndex);
					}
				}
			});
			
			const goToSlide = index => {
				if (index < 0) {
					activeIndex = 0;
				} else if (index > SLIDES.length - 1) {
					activeIndex = SLIDES.length - 1;
				} else {
					activeIndex = index;
				}
				setActiveSlide(activeIndex);
			};
			
			const setActiveSlide = index => {
				const ELEMENT = SLIDER?.querySelector('.active');
				if (ELEMENT) {
					ELEMENT?.classList.remove('active');
				}

				SLIDES[index]?.classList.add('active');
			};
			
			setActiveSlide(activeIndex);
			
		}
}

export default ProductCard;