import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const HomeNews = () => {
		const homeNewsSlider = new Swiper('.homeNewsSlider', {
			modules: [Navigation],
			lazy: true,
			rewind: true,
			slidesPerView: 1,
			spaceBetween: 25,
			grabCursor: true,
			navigation: {
				nextEl: '.swiper-button-next.news-arrow',
				prevEl: '.swiper-button-prev.news-arrow',
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1200: {
					slidesPerView: 3,
				}
			}
		});
}

export default HomeNews;