const YandexGoals = () => {

	const getSecondLevelDomain = (urlString) => {
		const url = new URL(urlString);
		const hostname = url.hostname;
		const domainParts = hostname.split('.');
		if (domainParts.length > 2) {
			return domainParts.slice(-2).join('.');
		}
		return hostname; 
	};

	
	if (getSecondLevelDomain(window.location.href) === 'frendom.ru') {
		try {
			// Добавить в корзину
			document.querySelector('.add_cart')?.addEventListener('click', () => ym(49797286,'reachGoal','dobavit_v_korziny'));
	
			// Купить в 1 клик
			document.querySelector('#buyToOneClickForm')?.addEventListener('submit', () => ym(49797286,'reachGoal','kupit_v_klik'));
	
			// Оставить отзыв о товаре
			document.querySelector('#productReviewSendForm')?.addEventListener('submit', () => ym(49797286,'reachGoal','ostavit_otzv'));
	
			// Оформление заявки
			document.querySelector('#simplecheckout_button_confirm')?.addEventListener('click', () => ym(49797286,'reachGoal','oformit_zakaz'));
	
			// Обратный звонок
			document.querySelector('#cf_header_callback')?.addEventListener('submit', () => ym(49797286,'reachGoal','zakazat_zvonok'));
	
		} catch (error) {
			console.error(error);
		}
	}
}

export default YandexGoals;