import { Modal } from "bootstrap";

window.bootstrap = { ...window.bootstrap, Modal };

class CityManager {
  constructor(options) {
    this.switchers = [];
    this.messages = [];
    this.options = Object.assign({}, { base_path: 'auto' }, options);
    this.url_module = this.getHttpHost() + 'index.php?route=extension/module/progroman/citymanager';
    this.cities_popup = (document.getElementById('prmn-cmngr-cities')) ? document.getElementById('prmn-cmngr-cities') : null;
    this.confirm_shown = false;
		this.host = null;

  }

  addSwitcher(el) {
    this.switchers.push(new CitySwitcher(el));
  };

  addMessage(el) {
    this.messages.push(new CityMessage(el));
  };

  loadData() {
    const self = this;
    let need_ajax = this.messages.length > 0;

    if (!need_ajax) {
      for (const i in this.switchers) {
        if (!this.switchers[i].loaded) {
          need_ajax = true;
        }
      }
    }
    if (need_ajax) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.url_module + '/init', true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const json = JSON.parse(xhr.responseText);
          for (const i in self.switchers) {
            if (self.switchers.hasOwnProperty(i)) {
              if (!self.switchers[i].loaded) {
                self.switchers[i].setContent(json.content);
              }
            }
          }

          if (json.messages) {
            for (const i in json.messages) {
              for (const j in self.messages) {
                if (self.messages.hasOwnProperty(j)) {
                  if (self.messages[j].key === i) {
                    self.messages[j].setContent(json.messages[i]);
                  }
                }
              }
            }
          }

          for (const i in self.messages) {
            if (self.messages.hasOwnProperty(i)) {
              self.messages[i].setDefault();
            }
          }
        }
      };
      xhr.send(`url=${encodeURIComponent(location.pathname + location.search)}`);
    }
  };

  showCitiesPopup() {
    if (this.cities_popup !== 'loading') {
			const modal = new bootstrap.Modal('#modalCities');
      const self = this;
      if (!this.cities_popup) {
        this.cities_popup = 'loading';
        const xhr = new XMLHttpRequest();
        xhr.open('GET', this.url_module + '/cities', true);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            self.hideAllConfirm();
						modal.show();
          }
        };
        xhr.send();
      } else {
        self.hideAllConfirm();
				modal.show();
      }
    }
  };

  hideAllConfirm() {
		const self = this;
    for (const i in self.switchers) {
      self.switchers[i].hideConfirm();
    }
  };

  setFias(id) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', this.url_module + '/save&fias_id=' + id, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        if (json.success) {
          location.reload();
        }
      }
    };
    xhr.send();
  };

  getHttpHost() {
    if (!this.host) {
      this.host = location.protocol + '//' + location.host + '/';

      if (this.options.base_path === 'auto') {
        const base = document.querySelector('base').getAttribute('href');
        let matches;
        if (base && (matches = base.match(/^http(?:s)?:\/\/[^/]*\/(.*)/))) {
          this.host += matches[1];
        }
      } else if (this.options.base_path) {
        this.host += this.options.base_path;
      }
    }

    return this.host;
  };

  confirmShown() {
    if (!this.confirm_shown) {
      this.confirm_shown = true;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.url_module + '/confirmshown', true);
      xhr.send();
    }
  };

	searchCities(input, cities) {
		const textVal = input.value.toLowerCase();

		cities.forEach((item) => {
			const text = item.textContent.toLowerCase();
			if (!text.startsWith(textVal)) {
				item.closest('.cities__city-name-col').style.display = 'none';
			} else {
				item.closest('.cities__city-name-col').style.display = 'block';
			}
		});
	}

	redirectToCity(cities) {
			cities.forEach(e => {
					e.addEventListener('click', event => {
							event.preventDefault();
							const url = window.location.href.substring(location.host.length + 9);
							
							location.href = event.target.href + url
					});
			})
	}

}

class CitySwitcher {
  constructor(el) {
    this.element = el;
    this.loaded = !el.innerHTML.trim() === '';
		this.confirm = el.querySelector('.prmn-cmngr__confirm');

    this.element.addEventListener('click', function (event) {
      const target = event.target;
      if (target.classList.contains('prmn-cmngr__confirm-btn')) {
        const value = target.dataset.value;
        const redirect = target.dataset.redirect;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', new CityManager().url_module + '/confirmclick', true);
        xhr.send();

        if (value == 'no') {
          new CityManager().showCitiesPopup();
        } else if (redirect) {
          location.href = redirect;
        }

				target.closest('.prmn-cmngr__confirm').style.display = 'none'
      }
    });

    this.showConfirm();
  }

  setContent(html) {
    if (!this.loaded) {
      this.element.innerHTML = html;
      this.loaded = true;
			this.confirm = this.element.querySelector('.prmn-cmngr__confirm');
      this.showConfirm();
    }
  };

  showConfirm() {
		if (this.confirm) {
      new CityManager().confirmShown();
      if (!(this.element.dataset.confirm === false)) {
        this.confirm.style.display = 'block';
      } else {
        this.confirm.remove();
      }
		}
  };

  hideConfirm() {
			this.confirm.style.display = 'none';
  };

}

class CityMessage {
  constructor(el) {
    this.element = el;
    this.key = el.dataset.key;
    this.default = el.dataset.default;
    el.removeAttribute('data-key');
    el.removeAttribute('data-default');
    this.found = false;
  }

  setContent(html) {
    this.element.innerHTML = html;
    this.found = true;
  };

  setDefault() {
    if (!this.found) {
      this.element.innerHTML = this.default;
    }
  };
}

const CityManagerInit = () => {
  const switchers = document.querySelectorAll('.prmn-cmngr'),
  			messages = document.querySelectorAll('.prmn-cmngr-message'),
  			cities = document.querySelectorAll('.prmn-cmngr-cities__city-name');

  if (switchers.length || messages.length) {
    const citymanager = new CityManager();
    switchers.forEach(function (switcher) {
      citymanager.addSwitcher(switcher);
    });
    messages.forEach(function (message) {
      citymanager.addMessage(message);
    });
    citymanager.loadData();

		document.querySelector('.prmn-cmngr-cities__search.search__input')?.addEventListener('input', event => citymanager.searchCities(event.target, cities));
		citymanager.redirectToCity(cities);
  }

}

export default CityManagerInit;
