import { Modal } from "bootstrap";

class Captcha {
    constructor(urlValidateCaptcha, urlGenerateCaptcha) {
        this.urlValidateCaptcha = urlValidateCaptcha;
        this.urlGenerateCaptcha = urlGenerateCaptcha;

        window.bootstrap = {...window.bootstrap, Modal};
    }

    onAddCaptcha() {
        return new Promise((resolve) => {
            const container = document.createElement('div');
            container.className = 'captcha-container';
            container.innerHTML = `
                <div class="modal fade captcha-modal" id="captchaModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="captcha-modal-title">Пожалуйста, подтвердите что вы не робот</div>

                                <div class="captcha-img-wrapper">
                                    <img id="img-captcha" src="${this.urlGenerateCaptcha}" alt="CAPTCHA" title="CAPTCHA">
                                </div>

                                <label>
                                    <span>Введите код с картинки</span>
                                    <input type="text" id="captchaInput">
                                    <span class="hidden captcha-error-message">Код неверный, попробуйте заново.</span>
                                </label>

                                <div class="d-flex gap-3 align-items-center mt-4 justify-content-center">
                                    <button type="button" id="captcha-reload-btn"><span class="icon-reload"></span></button>
                                    <button type="button" id="captcha-send-btn" class="btn btn-default btn-pink">Отправить</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            `;
            document.body.appendChild(container);

            const input = document.querySelector('#captchaInput');
            const modalEl = document.querySelector('#captchaModal');
            const modal = new bootstrap.Modal(modalEl);

            modal.show();

            // Привязываем обработчик события к кнопке перезагрузки CAPTCHA
            const reloadBtn = document.querySelector('#captcha-reload-btn');
            reloadBtn?.addEventListener('click', () => {
                console.log('Reload button clicked');
                this.onReloadCaptcha(this.urlGenerateCaptcha);
            });

            document.querySelector('#captchaInput')?.addEventListener('focus', () => this.onErrorCaptcha(input, false));

            modalEl?.addEventListener('hide.bs.modal', () => {
                if (modalEl) {
                    this.onReloadCaptcha(this.urlGenerateCaptcha);
                    document.querySelector('.captcha-container').remove();
                }
            });

            document.querySelector('#captcha-send-btn')?.addEventListener('click', () => {
                this.onSendValidateCaptcha(this.urlValidateCaptcha, input, modal, () => {
                    this.onErrorCaptcha(input, true);
                    this.onReloadCaptcha(this.urlGenerateCaptcha);
                })
                .then((data) => {
                    if (data.valid) {
                        resolve(data);
                    }
                });
            });
        });
    }

    onErrorCaptcha(input, error) {
        const errorElement = document.querySelector('.captcha-error-message');

        if (error) {
            input.style.borderColor = '#dc4545e3';
            errorElement.classList.remove('hidden');
        } else {
            input.style.borderColor = '';
            errorElement.classList.add('hidden');
        }
    }

    onReloadCaptcha(urlGenerate) {
        const captchaImg = document.querySelector('#img-captcha');
        captchaImg.src = urlGenerate + '?' + new Date().getTime(); // Обновление URL с временной меткой
        console.log('Captcha reloaded:', captchaImg.src); // Логирование для проверки
    }

    onSendValidateCaptcha(urlValidate, input, modal, callback) {
        return Promise.resolve(fetch(urlValidate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'captcha=' + input.value
            })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Возникла ошибка, повторите попытку позже.');
                }
            })
            .then((data) => {
                if (data.valid) {
                    modal.hide();
                    return data;
                } else {
                    callback();
                }
            })
            .catch((error) => {
                console.log(error);
                throw error;
            }))
    }
}

export default Captcha;
