import noUiSlider from 'nouislider';

const RangeSlider = () => {

	const addThousandSpacesSlider = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

	const $rangeFilterWrapper = document.querySelectorAll('.range-filter-wrapper'),
				url = new URL(window.location),
				params = url.searchParams;

	const checkActiveClearButton = () => {
		document.querySelectorAll('.catalog-filter-item').forEach(item => {
			if (params.has('sizes') && params.get('sizes').split(',').map(e => e.split('_')[0]).includes(item.getAttribute('data-id-attribute'))) {
					item.dataset.active = 'true';
					item.querySelector('.catalog-filter-item-clear').classList.remove('hidden');
			}
		});
	}

	checkActiveClearButton();

	$rangeFilterWrapper.forEach(wrapper => {
		const filterItem = wrapper.closest('.catalog-filter-item'),
					clearFilterItem = filterItem.querySelector('.catalog-filter-item-clear');
			

			const activeFilterItem = () => {
				filterItem.dataset.active = 'true';
				clearFilterItem.classList.remove('hidden');
			};

			const stepsSlider = wrapper.querySelector('.slider-multi-range'),
						inputMin = wrapper.querySelector('.js-inp-min'),
						inputMax = wrapper.querySelector('.js-inp-max'),
						inputs = [inputMin, inputMax];

			const start = stepsSlider.getAttribute('data-start'),
						end = stepsSlider.getAttribute('data-end'),
						max = stepsSlider.getAttribute('data-max'),
						min = stepsSlider.getAttribute('data-min'),
						step = stepsSlider.getAttribute('data-step');

			noUiSlider.create(stepsSlider, {
				start: [parseInt(start), parseInt(end)],
				connect: true,
				step: parseInt(step),
				range: {
						'min': parseInt(min),
						'max': parseInt(max)
				}
			});


			stepsSlider.noUiSlider.on('update', function (values, handle) {
				inputs[handle].value = addThousandSpacesSlider(Number(values[handle]));
			});

			stepsSlider.noUiSlider.on('start', function () {
				activeFilterItem();
			 });

			inputs.forEach(function (input, handle) {

				input.addEventListener('change', function () {
						stepsSlider.noUiSlider.setHandle(handle, this.value);
				});

				input.addEventListener('keydown', function (e) {

						const values = stepsSlider.noUiSlider.get();
						const value = Number(values[handle]);

						// [[handle0_down, handle0_up], [handle1_down, handle1_up]]
						const steps = stepsSlider.noUiSlider.steps();

						// [down, up]
						const step = steps[handle];

						let position;

						// 13 is enter,
						// 38 is key up,
						// 40 is key down.
						switch (e.which) {

								case 13:
										stepsSlider.noUiSlider.setHandle(handle, this.value);
										break;

								case 38:

										// Get step to go increase slider value (up)
										position = step[1];

										// false = no step is set
										if (position === false) {
												position = 1;
										}

										// null = edge of slider
										if (position !== null) {
												stepsSlider.noUiSlider.setHandle(handle, value + position);
										}

										break;

								case 40:

										position = step[0];

										if (position === false) {
												position = 1;
										}

										if (position !== null) {
												stepsSlider.noUiSlider.setHandle(handle, value - position);
										}

									break;
							}
					});
				});

			clearFilterItem.addEventListener('click', (event) => {
				const currentFilterItem = event.currentTarget.closest('.catalog-filter-item'),
							currentIdAttribute = currentFilterItem.getAttribute('data-id-attribute');

				checkActiveClearButton();

				if (params.has('sizes')) {
					const sizes = params.get('sizes').split(',');
					const filteredSizes = sizes.filter(size => size.split('_')[0] !== currentIdAttribute);
					const modifiedSizes = filteredSizes.join(',');
				
					if (modifiedSizes === '') {
						params.delete('sizes');
					} else {
						params.set('sizes', modifiedSizes);
					}
				
					const modifyUrl = url.toString().replace(/\+/g, '%20');
					window.history.pushState({}, '', modifyUrl);
				}

				currentFilterItem.querySelectorAll('.range-filter-wrapper').forEach(wrapper => {
					const $range = wrapper.querySelector('.slider-multi-range'),
								min = $range.getAttribute('data-min'),
								max = $range.getAttribute('data-max');

					$range.noUiSlider.set([parseInt(min), parseInt(max)]);
				});

				currentFilterItem.setAttribute('data-active', 'false')
				event.currentTarget.classList.add('hidden');
			});


	});



}

export default RangeSlider;