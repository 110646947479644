const HomeCounterNumbers = () => {

		function animateValue(element, start, end, duration) {
			let current = start;
			const range = end - start;

			if (element.textContent <= 1000) {
				const stepTime = Math.abs(Math.floor(duration / range));
				const timer = setInterval(() => {
					current += end > start ? 1 : -1;
					element.textContent = current;
					if (current === end) {
						clearInterval(timer);
					}
				}, stepTime);
			} else {
				const timerSecond = setInterval(() => {
					current += Math.floor(range / (duration / 10));
					if (current >= end) {
						clearInterval(timerSecond);
						current = end;
					}
					element.textContent = current;
				}, 10);
			}

		}
	
	function startCounters() {
		const counters = document.querySelectorAll(".count-number");
		const countNumbers = document.querySelector(".count-numbers");
		const windowHeight = window.innerHeight;

		function animateCounters() {
			const countNumbersTop = countNumbers.getBoundingClientRect().top;

			if (countNumbersTop < windowHeight) {
				counters.forEach((counter) => {
					if (!counter.classList.contains("counted")) {
						const counterDataCount = parseInt(counter.getAttribute("data-count"));
						let duration = 5000; // Minimum duration for fastest animation

						// Adjust duration for the "12,000" counter
						if (counterDataCount >= 1000) {
							duration = 5000; // Faster animation for "12,000"
						}

						animateValue(counter, 0, counterDataCount, duration);
						counter.classList.add("counted");
					}
				});

				// Remove scroll event listener after animation is triggered
				window.removeEventListener("scroll", animateCounters);
			}
		}

		if (countNumbers) {
			animateCounters();
			window.addEventListener("scroll", animateCounters);
		}

	}
	
	startCounters();
}

export default HomeCounterNumbers;