const Video = () => {

	const sliderPlay = document.querySelector('.product-card-btn-open-video');

	if (sliderPlay) {
		const video = document.querySelector('.product-video'),
					productWrapper = document.querySelector('.product-slider-wr'),
					wrapper = document.querySelector('.product-video-wrapper'),
					close = document.querySelector('.product-video-close');

		const classToggles = () => {
			productWrapper.classList.toggle('hidden');
			wrapper.classList.toggle('hidden');
		};

		close.addEventListener('click', () => {
			video.pause();
			classToggles();
		});

		sliderPlay.addEventListener('click', (event) => {
			event.preventDefault();

			if (video) {
				video.volume = 0.8;

				classToggles();
				video.play();
			}
		});

	}

}

export default Video;