import { Tab } from "bootstrap";

const ProductInfoTabs = () => {

	window.bootstrap = {...window.bootstrap, Tab};

	const triggerEl = document.querySelector('button[data-bs-target="#pills-characteristics"]');
	const link = document.querySelector('.product-tab-about-link-characteristic');

	link?.addEventListener('click', () => {
		const tab = new bootstrap.Tab(triggerEl);
		tab.show();
	});

}

export default ProductInfoTabs;