import Swiper from 'swiper';
import { Scrollbar, FreeMode } from 'swiper/modules';

const AllCategories = () => {

	const catalogCategoriesSlider = new Swiper(".catalogCategoriesSlider", {
		modules: [Scrollbar, FreeMode],
		grabCursor: true,
		slidesPerView: 'auto',
		spaceBetween: 15,
		rewind: true,
		scrollbar: {
			el: '.swiper-scrollbar.catalog-categories-scrollbar',
			draggable: true,
		},
		freeMode: {
			enabled: true,
			sticky: false,
		},
		breakpoints: {
			992: {
				slidesPerView: 4,
				freeMode: {
					enabled: true,
					sticky: true,
				},
				spaceBetween: 20,
			},
			1200: {
				slidesPerView: 5,
				freeMode: {
					enabled: true,
					sticky: true,
				},
				spaceBetween: 20,
			},
			1400: {
				slidesPerView: 6,
				freeMode: {
					enabled: true,
					sticky: true,
				},
				spaceBetween: 20,
			},
		}
	});

}

export default AllCategories;