import Noty from 'noty';
import HideShowModals from "./HideShowModals";
import Captcha from './Services/Captcha';

	// Animations
	const defaultInputs = element => {
		element.previousElementSibling.style.cssText = '';
		setTimeout(() => {
			element.previousElementSibling.lastElementChild.style.cssText = '';
		}, 600);
	};

	const activeIputs = element => {
		element.previousElementSibling.style.cssText = 'left: calc(100% - 45px);';
		element.previousElementSibling.lastElementChild .style.cssText = 'opacity: 0';
	}

	const checkInputs = element => {
		if (element.value.trim() === '') {
			defaultInputs(element);
		} else {
			activeIputs(element);
		}
	}

	const success = () => {
		document.querySelectorAll('.form-cf-header-item').forEach(e => {
			e.classList.toggle('hidden');
		});

		document.querySelectorAll('.form__input').forEach(e => {
			defaultInputs(e);
		});

		document.querySelector('.form__btn').classList.toggle('mail-success');
		document.querySelector('.container-mail').classList.toggle('mail-success');

		setTimeout(() => {
			document.querySelector('.mail__letter').classList.toggle('move');
			document.querySelector('.mail__top').classList.toggle('closed');
			document.querySelector('.form__btn--invisible').classList.toggle('visible');
			document.querySelector('.form__btn--visible').classList.toggle('invisible');
		}, 1000);
	};

	const ContactForm = () => {

		document.querySelectorAll('.form__input')?.forEach(e => {

			checkInputs(e);

			e.addEventListener('focus', event => {
				activeIputs(event.target);
			});

			e.addEventListener('blur', event => {
				checkInputs(event.target);
			});

		});



		document.querySelector('.contact-form')?.addEventListener('submit', async function (event) {
			event.preventDefault();
			const form = event.target;

			const captcha = new Captcha('/catalog/controller/extension/captcha/validate.php', '/catalog/controller/extension/captcha/captcha.php');
			

			try {
				await captcha.onAddCaptcha();

				const response = await fetch('index.php?route=mail/mail_send/send', {
					method: 'POST',
					body: new FormData(form)
				});
	
				if (response.ok) {
					const data = await response.json();
	
					if (data.captcha && data.result === 'success') {
						console.log(213)
						form.reset();
						success();
						setTimeout(() => {
							HideShowModals.hideModal('headerCallBack');
							success(); 
						}, 3700);
					} else {
						throw new Error('Заявка не отправлена. Попробуйте позже');
					}
				} else {
					throw new Error(`Ошибка на сервере: ${data.status}`);
				}
			} catch (error) {
				new Noty({
					theme: 'relax',
					type: 'error',
					timeout: 3000,
					text: error.message,
				}).show();
				console.error(error);
				return;
			}


		});

	}

export { ContactForm, checkInputs };
