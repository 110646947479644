const MobileMenu = () => {

	!function (t) {
		"use strict";
		var el = document.createElement('div');
		var animationsSupported = el.style.animation !== undefined || el.style.WebkitAnimation !== undefined ||
				el.style.MozAnimation !== undefined || el.style.msAnimation !== undefined || el.style.OAnimation !== undefined;
		var animationEndEvent = (animationsSupported) ? 'animationend' : 'webkitAnimationEnd oanimationend MSAnimationEnd';
	
		function n(t, e) {
				for (var i in e) e.hasOwnProperty(i) && (t[i] = e[i]);
				return t
		}
	
		function r(t, e, i) {
				this.el = t, this.parentLevel = e, this.position = i, this.childLevel = null;
				var n = t.getElementsByTagName("a");
				this.name = n && n.length ? n[0].innerHTML : null
		}
	
		function a(t, e, i) {
				this.el = t, this.current = !1, this.parentLevel = e, this.item = i || null;
				for (var n = [], s = [], o = 0, c = this.el.children, l = 0; l < c.length; l++) {
						var m = c[l];
						if ("li" === m.tagName.toLowerCase()) {
								i = new r(m, this, o++);
								n.push(i), /current-menu-item/.test(m.className) && (this.current = !0);
								for (var u = 0; u < m.children.length; u++) {
										var h = m.children[u];
										if ("ul" === h.tagName.toLowerCase()) {
												var d = new a(h, this, i);
												i.childLevel = d, s.push(d)
										}
								}
						}
				}
				this.items = n, this.children = s
		}
	
		function s(t) {
				this.root = new a(
						function (t, e) {
								e = e.toLowerCase();
								for (var i = [t]; i.length > 0;) {
										var n = i.shift();
										if (n.tagName.toLowerCase() === e) return n;
										Array.prototype.push.apply(i, n.children)
								}
								return !1
						}(t, "ul"), null
				)
		}
	
		function o(t, e) {
				this.el = t, this.options = n({}, this.options), n(this.options, e), this.structure = new s(t), this._init()
		}
	
		r.prototype.getName = function () {
				return this.name
		};
		a.prototype.isRoot = function () {
				return null === this.parentLevel
		};
		s.prototype.getCurrent = function () {
				for (var t = [this.root]; t.length > 0;) {
						var e = t.shift();
						if (!0 === e.current) return e;
						Array.prototype.push.apply(t, e.children)
				}
				return this.root.current = !0, this.root
		};
		s.prototype.getAllItems = function () {
				for (var t = [], e = [this.root]; e.length > 0;) {
						var i = e.shift();
						Array.prototype.push.apply(t, i.items), Array.prototype.push.apply(e, i.children)
				}
				return t
		};
		o.prototype.options = {
				breadcrumbsCtrl: !0,
				initialBreadcrumb: "all",
				backCtrl: !0,
				itemsDelayInterval: 60,
				direction: "r2l",
				onItemClick: function (t, e) {
						return !0
				}
		};
		o.prototype._init = function () {
				this.structure.getCurrent().el.classList.add("dcm__level--current"), this.options.backCtrl &&
				(this.backCtrl = document.createElement("button"), this.backCtrl.className = "dcm__back dcm__back--hidden",
						this.backCtrl.setAttribute("aria-label", "Go back"), this.backCtrl.innerHTML =
						'<span class="dcm__icon dcm__icon--arrow-left"></span>', this.el.insertBefore(this.backCtrl, this.el.firstChild)),
				this.options.breadcrumbsCtrl &&
				(this.breadcrumbsCtrl = document.createElement("nav"), this.breadcrumbsCtrl.className = "dcm__breadcrumbs",
						this.el.insertBefore(this.breadcrumbsCtrl, this.el.firstChild),
						this._addInitialBreadcrumbs(this.structure.getCurrent())), this._initEvents()
		};
		o.prototype._initEvents = function () {
				var t = this;
				this.structure.getAllItems().forEach(function (e) {

						e.el.querySelector(".menu-item-children-link")?.addEventListener("click", function (i) {
							i.preventDefault();
							t._openSubmenu(e);
						});

						e.el.querySelector("a").addEventListener("click", function (i) {
							const shouldPreventDefault = !t.options.onItemClick(i, e.getName());
							if (shouldPreventDefault) {
									i.preventDefault();
							}
						});

		/* 				e.el.querySelector("a").addEventListener("click", function (i) {
								e.childLevel ? (i.preventDefault(), t._openSubmenu(e)) :
										!1 === t.options.onItemClick(i, e.getName()) && i.preventDefault()
						}) */
				}), this.options.backCtrl && this.backCtrl.addEventListener("click", function () {
						t._back()
				})
		};
		o.prototype._openSubmenu = function (t) {
				if (this.isAnimating) return !1;
				this.isAnimating = !0;
				var e = this._menuOut(t.position);
				this._menuIn(t.childLevel, e, t.position)
		};
		o.prototype._back = function () {
				if (this.isAnimating) return !1;
				this.isAnimating = !0;
				var t = this.structure.getCurrent(),
						e = t.isRoot() ? t : t.parentLevel, i = this._menuOut();
				this._menuIn(e, i), this.options.breadcrumbsCtrl &&
				this.breadcrumbsCtrl.removeChild(this.breadcrumbsCtrl.lastElementChild)
		};
		o.prototype._menuOut = function (t) {
				var e = this, i = this.structure.getCurrent(), n = i.el, r = void 0 === t;
				return i.current = !1, i.items.forEach(function (i) {
						var n = i.el, a = null;
						a = r ? parseInt(i.position * e.options.itemsDelayInterval) + "ms" :
								parseInt(Math.abs(t - i.position) * e.options.itemsDelayInterval) + "ms",
								n.style.WebkitAnimationDelay = n.style.animationDelay = a
				}), "r2l" === this.options.direction ? n.classList.add(r ? "dcm-animate-outToRight" : "dcm-animate-outToLeft") :
						n.classList.add(r ? "dcm-animate-outToLeft" : "dcm-animate-outToRight"), i
		};
		o.prototype._menuIn = function (t, n, r) {
				var a = this, s = (n = n || this.structure.getCurrent()).el, o = void 0 === r,
						c = t.el, l = t.items, m = l.length;
				l.forEach(function (n, l) {
						var u, h, d = n.el;
						d.style.WebkitAnimationDelay = d.style.animationDelay =
								o ? parseInt(l * a.options.itemsDelayInterval) + "ms" :
										parseInt(Math.abs(r - l) * a.options.itemsDelayInterval) + "ms", l === (r <= m / 2 || o ? m - 1 : 0) &&
						(u = function () {
								"r2l" === a.options.direction ?
										(s.classList.remove(o ? "dcm-animate-outToRight" : "dcm-animate-outToLeft"),
												c.classList.remove(o ? "dcm-animate-inFromLeft" : "dcm-animate-inFromRight")) :
										(s.classList.remove(o ? "dcm-animate-outToLeft" : "dcm-animate-outToRight"),
												c.classList.remove(o ? "dcm-animate-inFromRight" : "dcm-animate-inFromLeft")),
										s.classList.remove("dcm__level--current"), s.current = !1,
										c.classList.add("dcm__level--current"), t.current = !0,
										o ? t.isRoot() && a.options.backCtrl && a.backCtrl.classList.add("dcm__back--hidden") :
												(a.options.backCtrl && a.backCtrl.classList.remove("dcm__back--hidden"),
														a._addBreadcrumb(t)), a.isAnimating = !1
						}, h = function (t) {
								if (animationsSupported) {
										if (t.target != this) return;
										this.removeEventListener(animationEndEvent, h)
								}
								u && "function" == typeof u && u.call()
						}, animationsSupported ? d.addEventListener(animationEndEvent, h) : h())
				}), "r2l" === this.options.direction ?
						c.classList.add(o ? "dcm-animate-inFromLeft" : "dcm-animate-inFromRight") :
						c.classList.add(o ? "dcm-animate-inFromRight" : "dcm-animate-inFromLeft")
		};
		o.prototype._addInitialBreadcrumbs = function (t) {
				t.isRoot() || this._addInitialBreadcrumbs(t.parentLevel), this._addBreadcrumb(t)
		};
		o.prototype._addBreadcrumb = function (t) {
				if (!this.options.breadcrumbsCtrl) return !1;
				var e = document.createElement("a");
				e.innerHTML = t.item ? t.item.getName() : this.options.initialBreadcrumb,
						this.breadcrumbsCtrl.appendChild(e);
				var i = this;
				e.addEventListener("click", function (n) {
						if (n.preventDefault(), !e.nextSibling || i.isAnimating) return !1;
						i.isAnimating = !0;
						var r, a = i._menuOut(), s = t;
						for (i._menuIn(s, a); r = e.nextSibling;) i.breadcrumbsCtrl.removeChild(r)
				})
		};
		t.DCMenu = o
	}(window);


	const _init = () => {
		
	document.addEventListener('click', function(e) {
		if (e.target && e.target.classList.contains('main-btn')) {
			e.target.classList.toggle('active');
		}
	});

	const menuEl = document.getElementById( 'dcm-menu' ),
				mlmenu = new DCMenu( menuEl , {
					breadcrumbsCtrl: true, // show breadcrumbs
					initialBreadcrumb: 'Каталог', // initial breadcrumb text
					backCtrl: true, // show back button
					itemsDelayInterval: 60, // delay between each menu item sliding animation
					//onItemClick: () => {}
				});

	const openMenuCtrl = document.querySelector('.dcm__action--open'),
			closeMenuCtrl = document.querySelector('.dcm__action--close');

	function openMenu() {
		menuEl.classList.add('dcm__nav--open');
		document.querySelector('body').classList.add('body-ov-hidden');

		document.querySelector('.dcm__breadcrumbs').insertAdjacentHTML('afterbegin', '<a>Каталог</a>');
		}

		function closeMenu() {
			menuEl.classList.remove('dcm__nav--open');
		document.querySelector('body').classList.remove('body-ov-hidden');

		document.querySelector('.dcm__breadcrumbs').innerHTML = '';
		}

	openMenuCtrl.addEventListener('click', openMenu);
	closeMenuCtrl.addEventListener('click', closeMenu);
	
	document.querySelector('.dcm__back').click();

	document.querySelectorAll('.menu-item-no-link').forEach(e => {
		e.addEventListener('click', event => event.preventDefault())
	});

}

	_init();
}

export default MobileMenu;