import IMask from 'imask';

const InputMask = () => {

	document.querySelectorAll('.phone-mask')?.forEach(elem => {
			IMask(elem, {
				mask: '+{7} (000) 000-00-00',
				lazy: true
			});
	});

}

export default InputMask;