import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

const Articles = () => {
		const articlesSlider = new Swiper('.articlesSlider', {
			modules: [Navigation, Scrollbar],
			lazy: true,
			rewind: true,
			direction: 'horizontal',
			slidesPerView: 1,
			centeredSlides: false,
			speed: 700,
			spaceBetween: 30,
			grabCursor: true,
			navigation: {
				nextEl: '.swiper-button-next.articles-arrow',
				prevEl: '.swiper-button-prev.articles-arrow',
			},
			scrollbar: {
				el: '.swiper-scrollbar.articles-scrollbar',
				draggable: true,
			},
			breakpoints: {
				992: {
					direction: 'horizontal',
					slidesPerView: 2,
					centeredSlides: false,
				},
				1200: {
					direction: 'vertical',
					slidesPerView: 3,
					centeredSlides: false,
				},
			}
		});
}

export default Articles;