const HomeVideoAbout = () => {

		const video = document.querySelector('#home_video'),
					play = document.querySelector('.home-video-btn .puntero'),
					sound = document.querySelector('.home-video-sound-btn');
		
		if (video && sound) {
			video.volume = 0.6;

			const soundCheck = () => {
				sound.classList.toggle('sound-on');
				(sound.classList.contains('sound-on')) ? video.volume = 0.6 : video.volume = 0;
			}

			if (play) {
				play.addEventListener('click', (event) => {
					document.querySelector('.home-video-btn').classList.toggle('active');
	
					(!sound.classList.contains('sound-on')) && soundCheck();
					
					if (video.paused) {
						video.play();
						sound.classList.toggle('active');
					} else {
						video.pause();
						sound.classList.toggle('active');
					}
	
				});
			}


			sound.addEventListener('click', soundCheck);
		}

}

export default HomeVideoAbout;