import { Offcanvas } from 'bootstrap';
import Noty from 'noty';
import { LoadContent } from "../../Services/LoadContent";
import { checkTotal } from "./Services";

const CartAdd = () => {

	window.bootstrap = {...window.bootstrap, Offcanvas};
	
	document.querySelectorAll('.add_cart')?.forEach(e => {
		e.addEventListener('click', () => {

				const cartCanvas = new bootstrap.Offcanvas('#header_cart_canvas');
				const options = document.querySelectorAll('.product-option');
				const product_id = document.getElementById('product-product').getAttribute('data-product_id');
				const clothConfig = document.querySelector('.product-cloth-config-item');
				const option_price = document.querySelector('.product-config-value[data-change_price]');
				const formData = new URLSearchParams();

				options.forEach((option) => {
					if (option.type == 'radio' && option.checked || option.type != 'radio') {
						formData.append(option.getAttribute('name'), option.value);
					}
				});
				
				formData.append('product_id', product_id);

				if (clothConfig) {
					formData.append('material_category_id', clothConfig.querySelector('input[data-category_id]').getAttribute('data-category_id'));
					formData.append('cloth_id', clothConfig.querySelector('input[data-cloth_id]').getAttribute('data-cloth_id'));
					formData.append('cloth_img_id', clothConfig.querySelector('input[data-cloth_img_id]').getAttribute('data-cloth_img_id'));
					formData.append('cloth_image', clothConfig.querySelector('input[data-cloth_img]').getAttribute('data-cloth_img'));
				}

				if (option_price /* && option_price.getAttribute('data-change_price') == '1' */) {
					if (option_price.getAttribute('data-option_values') && option_price.getAttribute('data-option_values') != '') {
						formData.append('option_value', option_price.getAttribute('data-option_values'));
					} else {
						formData.append('option_value', option_price.textContent);
					}
					formData.append('option_id', option_price.getAttribute('data-global_option_id'));
				}

				fetch('index.php?route=checkout/cart/add', {
					method: 'POST',
					body: formData
				})
				.then(response => {
					if (response.ok) {
						return response.json();
					} else {
						throw new Error('Ошибка на сервере: ' + response.statusText);
					}
				})
				.then(json => {
					console.log(json);
					if (json['error'] && json['error']['option']) {
						throw new Error('У вас не заполнены опции: ' + json['error']['option'])
					}

					if (json['success']) {
						LoadContent({
							parentSelector: '#header_cart_canvas',
							url: 'index.php?route=common/cart/info',
							elementSelector: '.offcanvas-body, .cart-canvas-bottom-wrapper, .offcanvas-header',
							cleanParent: true
						})
						document.querySelectorAll('.cart-total-num').forEach(e => e.textContent = json['total']);
						checkTotal('.cart-total-num');
						cartCanvas.show();
					}
				})
				.catch(error => {
					new Noty({
						theme: 'relax',
						type: 'error',
						timeout: 3500,
						text: error.message,
					}).show();
					console.error(error);
				})
		});
	});



}

export default CartAdd;