import { Toast } from 'bootstrap';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const Compare = () => {

		window.bootstrap = {...window.bootstrap, Toast};

		try {
					// Add and remove product to compare
					let compareProducts = [];
					const compareTotal = document.querySelectorAll('.compare-total'),
								compareBtns = document.querySelectorAll('.compare-add');
			
					// check products compare for session
					function checkProductsCompareSession() {
						fetch('index.php?route=product/compare/checkCompareProduct', {
							method: 'POST',
							headers: {'Content-Type': 'application/json'}
						})
						.then(response => {
							if (response.ok) {
								return response.json();
							} else {
								throw new Error('Возникла ошибка, повторите попытку позже.');
							}
						})
						.then(json => {
							if (json['success'] && json['success'] == 'true') {
								compareProducts = json['products'];
								checkCompareTotal();
								checkCompareIcons();
								compareAddRemove();
							} else {
								compareAddRemove();
								clearCompareIcons();
							}
						})
						.catch(error => {
							console.error(error);
						});
					}
			
					function clearCompareIcons() {
						compareBtns.forEach((e) => {
							e.querySelector('.icon-compare').style.cssText = '';
						});
					}
			
					// check compare total
					function checkCompareTotal() {
						compareTotal.forEach(e => {
							if (e.textContent == '0') {
								e.classList.add('empty');
								clearCompareIcons();
							} else {
								e.classList.remove('empty');
							}
						});
					}
			
					// icon compare - check active
					function checkCompareIcons() {
						compareBtns.forEach(element => {
							const productsId = element.getAttribute('data-product_id'),
										icons = element.querySelector('.icon-compare');
			
							if (compareProducts.includes(productsId)) {
								icons.style.cssText = 'color: red';
							} else {
								icons.style.cssText = '';
							}
						});
					}
			
					function compareAddRemove() {
						compareBtns.forEach((e) => {
			
							e.addEventListener('click', (event) => {
								const th = event.currentTarget,
											productId = th.dataset.product_id;
					
								const compare = (url) => {	
									document.querySelector('.toast-container').innerHTML = '';
				
									fetch(url, {
										method: 'POST',
										headers: {
											'Content-Type': 'application/x-www-form-urlencoded'
										},
										body: 'product_id=' + productId
									})
									.then(response => {
										if (response.ok) {
											return response.json();
										} else {
											throw new Error('Возникла ошибка, повторите попытку позже.');
										}
									})
									.then(json => {
										if (json['success']) {
											
											setTimeout(() => {
				
												compareTotal.forEach(e => {
													e.textContent = json['total'];
												});
				
												// success toast
												document.querySelector('.toast-container').insertAdjacentHTML('beforeend', `
												<div id="successCompare${productId}" class="toast custom-add-toast" role="alert" aria-live="assertive" aria-atomic="true">
												<div class="toast-header">
													<img src="/image/frendom-balls.svg" alt="диваны.frendom">
														<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
													</div>
													<div class="toast-body">${json['success']}</div>
												</div>
											`);
						
											const toast = document.querySelector(`#successCompare${productId}`);
		
											if (toast) {
												const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toast);
												toastBootstrap.show();
											}
						
											setTimeout(() => {
												toast.remove();
											}, 7000);
				
											if (!compareProducts.includes(productId)) {
													compareProducts.push(...compareProducts, productId);
											} else {
													compareProducts = compareProducts.filter(id => id !== productId);
											}
			
											checkProductsCompareSession();
											checkCompareTotal();
											checkCompareIcons();
				
										}, 100);
				
									}
								})
								.catch(error => {
									console.error(error);
								});
				
								}
				
								if (compareProducts.includes(productId)) {
									compare('index.php?route=product/compare/remove');
								} else {
									compare('index.php?route=product/compare/add');
								}
				
							});
					
						});
					}
			
					checkProductsCompareSession();
					checkCompareTotal();
			
			
				// Compare page sliders
					const compareSliders = document.querySelectorAll('.pageCompare');
		
					compareSliders?.forEach(e => {
						const prev = e.parentElement.querySelector('.arrows-compare-wr .swiper-button-prev.page-compare-arrows'),
									next = e.parentElement.querySelector('.arrows-compare-wr .swiper-button-next.page-compare-arrows');
		
						const swiper = new Swiper(e, {
								modules: [Navigation],
								slidesPerView: 2,
								spaceBetween: 20,
								rewind: true,
								grabCursor: true,
								navigation: {
									nextEl: next,
									prevEl: prev,
								},
								breakpoints: {
									992: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
									1400: {
										slidesPerView: 4,
										spaceBetween: 30,
									},
								}
						});
					});
		} catch (error) {
			console.error(error);
		}



}

export default Compare;

