import Cookies from 'js-cookie';
import Noty from 'noty';
import Compare from "../Compare";
import CustomSelect from '../CustomSelect';
import Favourites from "../Favourites";
import ProductCard from "../ProductCard";

const SortSelect = () => {

		const saveSortItems = (element) => {
			const url = new URL(document.location),
						searchParams = new URLSearchParams(url.search),
						values = element.getAttribute('data-value').split('&'),
						productsWrapper = document.querySelector('.catalog-products-wrapper .row');

			values.forEach(param => {
				searchParams.set(param.split('=')[0], param.split('=')[1]);
			});

			const newUrl = url.origin + url.pathname + '?' + searchParams.toString();

			if (searchParams.has('offset') && searchParams.has('limit')) {
				searchParams.set('limit', +searchParams.get('offset') + +searchParams.get('limit'));
				searchParams.set('offset', 0);
			}

		fetch(url.origin + url.pathname + '?' + searchParams.toString())
		.then(response => {
			if (response.ok) {
				return response.text();
			} else {
				throw new Error('Ошибка на сервере: ' + response.statusText);
			}
		})
		.then(data => {
			const parser = new DOMParser();
			const htmlDocument = parser.parseFromString(data, 'text/html');
			const wrapper = htmlDocument.querySelector('.catalog-products-wrapper .row');

			productsWrapper.innerHTML = '';
			Array.from(wrapper.querySelectorAll('.catalog-product-item')).reverse().forEach(e => {
				productsWrapper?.insertAdjacentHTML('afterbegin', e.outerHTML);
			});

			history.replaceState({}, '', newUrl);

			Cookies.remove('sortItems');
			Cookies.set('sortItems', values);

			new Favourites();
			Compare();
			ProductCard();
		})
		.catch(error => {
			new Noty({
				theme: 'relax',
				type: 'error',
				timeout: 3000,
				text: error.message,
			}).show();
			console.error(error);
		});

	}

		if (document.querySelector('#catalog_sort')) CustomSelect('#catalog_sort', {saveSortItems})
}

export default SortSelect;