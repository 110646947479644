import Video from "./Video";
import ProductSlider from "./ProductSlider";
import ProductInfoTabs from "./ProductInfoTabs";
import OptionChange from "./OptionChange";
import ClothViewModeResult from "./ClothViewMode";
import { ProductConfigurationResult } from "./ProductConfiguration";
import Reviews from "./Reviews";
import RecommendedProducts from "./RecommendedProducts";
import BuyToOneClick from "./BuyToOneClick";
import CartAdd from "./Cart/CartAdd";
import CartRemove from "./Cart/CartRemove";
import CartUpdate from "./Cart/CartUpdate";
import LoadingArModels from "./LoadingArModels";


const Product = () => {

	ProductSlider();

	Video();

	ProductInfoTabs();

	ProductConfigurationResult();

	ClothViewModeResult();

	OptionChange();

	Reviews();

	RecommendedProducts();

	BuyToOneClick();

	CartRemove();
	
	CartAdd();

	CartUpdate();

	LoadingArModels();

}


export default Product;