import Noty from 'noty';

const LoadContent = ({ parentSelector, url, elementSelector, cleanParent}) => {
  return Promise.resolve(fetch(url)
				.then(response => {
					if (response.ok) {
						return response.text();
					} else {
						throw new Error(`Ошибка на сервере: ${response.statusText}`);
					}
				})
				.then(data => {
					const parser = new DOMParser();
					const htmlDocument = parser.parseFromString(data, 'text/html');
					const elements = htmlDocument.querySelectorAll(elementSelector);
					const targetElement = document.querySelector(parentSelector);

					if (cleanParent) targetElement.innerHTML = '';
				
					elements.forEach(el => {
						targetElement.appendChild(el);
					});

					return { data, elements, targetElement };
				})
				.catch(error => {
					console.error(error);
					new Noty({
						theme: 'relax',
						type: 'error',
						timeout: 3500,
						text: error.message,
					}).show();
				})
		);
}

export { LoadContent };
