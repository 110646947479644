import Swiper from 'swiper';
import { Pagination, EffectCards, EffectCreative, EffectFade } from 'swiper/modules';

const ArticlesPage = () => {
	
	const articleCartSlider1 = new Swiper(".articleCartSlider1", {
		modules: [Pagination, EffectCards],
		speed: 1000,
		effect: "cards",
		grabCursor: true,
		pagination: {
			el: ".article-cart-pagination",
			clickable: true
		},
	});

	const articleCartSlider2 = new Swiper(".articleCartSlider2", {
		modules: [Pagination, EffectCreative],
		speed: 1000,
		grabCursor: true,
		pagination: {
			el: ".article-cart-pagination2",
			clickable: true
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		rewind: true
	});

	const articleCartSlider3 = new Swiper(".articleCartSlider3", {
		modules: [Pagination, EffectFade],
		speed: 1000,
		grabCursor: true,
		pagination: {
			el: ".article-cart-pagination3",
			clickable: true
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		rewind: true
	});
}

export default ArticlesPage;