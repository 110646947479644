class ReviewsSendStars {
	constructor(qs) {
		this.ratings = [
			{id: 1, name: "Ужасно"},
			{id: 2, name: "Плохо"},
			{id: 3, name: "Нормально"},
			{id: 4, name: "Хорошо"},
			{id: 5, name: "Отлично"}
		];
		this.rating = null;
		this.el = document.querySelector(qs);

		this.init();
	}
	init() {
		this.el?.addEventListener("change",this.updateRating.bind(this));

	}
	updateRating(e) {
		// clear animation delays
		Array.from(this.el.querySelectorAll(`[for*="rating"]`)).forEach(el => {
			el.className = "rating__label";
		});

		const ratingObject = this.ratings.find(r => r.id === +e.target.value);
		const prevRatingID = this.rating?.id || 0;

		let delay = 0;
		this.rating = ratingObject;
		this.ratings.forEach(rating => {
			const { id } = rating;

			// add the delays
			const ratingLabel = this.el.querySelector(`[for="rating-${id}"]`);

			if (id > prevRatingID + 1 && id <= this.rating.id) {
				++delay;
				ratingLabel.classList.add(`rating__label--delay${delay}`);
			}

			// hide ratings to not read, show the one to read
			const ratingTextEl = this.el.querySelector(`[data-rating="${id}"]`);

			this.el.querySelector('[name="rating_total"]').value = this.rating.id;

			if (this.rating.id !== id) {
				ratingTextEl.setAttribute("hidden",true);
			} else {
				ratingTextEl.removeAttribute("hidden");
			}
		});
	}
}

export default ReviewsSendStars;