import { Modal } from "bootstrap";
import Swiper from 'swiper';
import { Manipulation, Navigation } from 'swiper/modules';
import { Configuration } from './ProductConfiguration';

window.bootstrap = {...window.bootstrap, Modal};

const configuration = new Configuration();

class СlothesViewMode {
	constructor() {
		this.currentClothId = '';
		this.viewMode = false;
	}

	slider = () => {
		const swiper = new Swiper('.productClothImagesSlider', {
			modules: [Manipulation, Navigation],
			allowTouchMove: false,
			spaceBetween: 30
		});

		return swiper;
	}

	changeClothLink = (img) => {
		const lastSlashIndex = img.lastIndexOf("/");
		const resultImage = img.slice(0, lastSlashIndex) + "/img" + img.slice(lastSlashIndex);

		return resultImage;
	}

	slideDomElement = (id, img, name, parentName, idParent, idCurrent, idCategory) => {
		return `
			<div class="swiper-slide" id="${id}"><img src="${this.changeClothLink(img)}" alt="${name}" title="${name}" />
				<div class="d-flex align-items-center gap-3 w-100 mt-3 px-0 px-md-5 flex-wrap justify-content-between">
					<button class="btn btn-default btn-pink slide-cloth-img-item" type="button" data-elementid="${idCurrent}" data-category_id="${idCategory}" data-parentid="${idParent}" data-parentname="${parentName}" data-name="${name}" data-img="${img}">Выбрать ткань</button>
					<h6>${name}</h6>
				</div>
			</div>
		`;
	}

	clothElement = (elem) => {
			return {
				img: elem.dataset.img,
				name: elem.dataset.name,
				parentName: elem.dataset.parentname,
				id: `clothImageSlide${elem.dataset.parentid}${elem.dataset.elementid}`,
				idParent: elem.dataset.parentid,
				idCurrent: elem.dataset.elementid,
				idCategory: elem.dataset.category_id,
			}
	}

	changeClothId = (cloth) => {
		const { idParent, idCurrent } = this.clothElement(cloth);
		this.currentClothId = idParent + idCurrent;
	}

	onShowModal = () => {
		const modalsContainer = document.querySelector('.modal-clothes-container');

		if (modalsContainer.querySelectorAll('.modal-body div').length <= 0) {
				modalsContainer.innerHTML = `
					<div class="modal fade product-cloth-img-slider-modal" id="clothImagesSliderModal" tabindex="-1" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
							<div class="modal-header">
								<div class="modal-title">Режим просмотра</div>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
								<div class="modal-body">
									<button class="swiper-button-prev product-cloth-images-arrow swiper-custom-arrow" data-mode="prepend"></button>
									<button class="swiper-button-next product-cloth-images-arrow swiper-custom-arrow" data-mode="append"></button>
									<div class="swiper productClothImagesSlider"><div class="swiper-wrapper"></div></div>
								</div>
								<div class="modal-footer">
										<div>Вы выбрали ткань: <span></span></div>
								</div>
							</div>
						</div>
				</div>
			`;
		}
	}

	onAddActiveModalCloth = (name) => {
		document.querySelector('#clothImagesSliderModal .modal-footer span').textContent = name;
	}

	onChangeCloth = (button) => {
		const configs = document.querySelectorAll('.product-cloth-config-item'),
					clothes = document.querySelectorAll('.cloth-img-item');

		button.addEventListener('click', event => {
			configuration.onHandleCloth(event.currentTarget, configs, clothes);
			this.onAddActiveModalCloth(event.currentTarget.dataset.name);
			configuration.addCurrentActive(document.querySelector(`#clothImgItem${event.currentTarget.dataset.elementid}${event.currentTarget.dataset.parentid}`));

			document.querySelector('#clothImagesSliderModal .btn-close').click();
		});
	}

	onAddFirstSlide = (cloth, slider) => {
		const modal = new bootstrap.Modal('#clothImagesSliderModal');
		const { id, img, name, parentName, idCurrent, idParent, idCategory } = this.clothElement(cloth);

		if (document.querySelector('.productClothImagesSlider')) {
			if (!document.getElementById(this.clothElement(cloth).id)) {
				slider.addSlide(1, this.slideDomElement(id, img, name, parentName, idCurrent, idParent, idCategory));
				this.changeClothId(cloth);
				slider.update();
				modal.show();
				this.onAddActiveModalCloth(name);
				document.querySelector('.product-cloth-config').classList.add('hidden');
			}
		}

		this.onChangeCloth(slider.slides[slider.activeIndex].querySelector('.slide-cloth-img-item'));
	}

	onAddingSlide = (element, slider) => {
		const currentElement = document.querySelector(`#clothImgItem${this.currentClothId}`),
					mode = element.currentTarget.dataset.mode,
					neighboringElement = (mode == 'prepend') ? currentElement.previousElementSibling : currentElement.nextElementSibling,
					placeElement = (mode == 'prepend') ? slider.isBeginning : slider.isEnd;

		const { id, img, name, parentName, idCurrent, idParent, idCategory } = this.clothElement(neighboringElement);

				this.currentClothId = slider.slides[slider.activeIndex].getAttribute('id').replace(/[^0-9]/g,"");

				if (placeElement && neighboringElement && !document.getElementById(this.clothElement(neighboringElement).id)) {
						if (mode == 'prepend') {
							slider.prependSlide(this.slideDomElement(id, img, name, parentName, idCurrent, idParent, idCategory));
						} else {
							slider.appendSlide(this.slideDomElement(id, img, name, parentName, idCurrent, idParent, idCategory));
						}
					this.changeClothId(neighboringElement);
				}

			if (mode == 'prepend') {
				slider.slidePrev();
			} else {
				slider.slideNext();
			}
			
			slider.update();

			this.onChangeCloth(slider.slides[slider.activeIndex].querySelector('.slide-cloth-img-item'));
	}

	onCleanSlides = (slider) => {
		document.querySelector('.modal-clothes-container .productClothImagesSlider .swiper-wrapper').innerHTML = '';
		this.currentClothId = '';
		slider.update();
		document.querySelector('.product-cloth-config').classList.remove('hidden');
	}

}

// Alone cloth image for view mode

/* 		buttons.forEach(e => {
			e.addEventListener('click', event => {
					const btn = event.currentTarget.parentElement,
								modalsContainer = document.querySelector('.modal-clothes-container');

					const id = `productClothImageZoomModal${btn.dataset.parentid}${btn.dataset.id}`,
								name = btn.dataset.parentname + ', ' + btn.dataset.name,
								img = btn.dataset.img;

					modalsContainer.innerHTML = `
					<div class="modal fade product-cloth-img-zoom-modal" id="${id}" tabindex="-1" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								<div class="modal-body">
									<img src="${onClothesViewMode.changeClothLink(img)}" alt="${name}" title="${name}" />
								</div>
							</div>
						</div>
					</div>
				`;

					const modal = new bootstrap.Modal(`#${id}`);
					modal.show();

					document.getElementById(id).addEventListener('hide.bs.modal', event => {
						modalsContainer.innerHTML = '';
					});
			});

	}); */

const ClothViewModeResult = () => {
	
	const onClothesViewMode = new СlothesViewMode();

	onClothesViewMode.onShowModal();

	const slider = onClothesViewMode.slider();

	document.querySelector('#productClothSwitchImagesSlider')?.addEventListener('click', () => onClothesViewMode.viewMode = !onClothesViewMode.viewMode);

	document.querySelectorAll('.cloth-img-item-full-size')?.forEach(e => {
		e.addEventListener('click', event => {
				const cloth = event.currentTarget.parentElement;
					onClothesViewMode.onAddFirstSlide(cloth, slider);
		});
	});

	document.querySelectorAll('.cloth-img-item')?.forEach(e => {
		e.addEventListener('click', event => {
			//if (onClothesViewMode.viewMode === true) onClothesViewMode.onAddFirstSlide(event.currentTarget, slider);

			if (window.innerWidth <= 992) {
				onClothesViewMode.onAddFirstSlide(event.currentTarget, slider);
			}
		});
	});

	document.querySelector('.swiper-button-next.product-cloth-images-arrow')?.addEventListener('click', event => onClothesViewMode.onAddingSlide(event, slider));

	document.querySelector('.swiper-button-prev.product-cloth-images-arrow')?.addEventListener('click', event => onClothesViewMode.onAddingSlide(event, slider));

	document.querySelector('#clothImagesSliderModal')?.addEventListener('hide.bs.modal', () => {
			onClothesViewMode.onCleanSlides(slider);
	});

}


export default ClothViewModeResult;