import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Parallax } from 'swiper/modules';

const HomeBanner = () => {

	const homeSlider = new Swiper(".homeSlider", {
		modules: [Navigation, Pagination, Autoplay, Parallax],
		lazy: true,
		speed: 600,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-slider-btn-next",
			prevEl: ".home-slider-btn-prev",
		},
		pagination: {
			el: ".home-slider-pag-mobile",
			clickable: true
		},
		parallax: true,
		autoHeight: true,
		breakpoints: {
			768: {
				pagination: {
					el: ".home-slider-pag",
					clickable: true
				},
			}
		}
	});

}

export default HomeBanner;