const CheckCookies = () => {
	const cookieDate = localStorage.getItem('cookieDate');
	const cookieNotification = document.getElementById('cookie_notification');
	const cookieBtn = cookieNotification?.querySelector('.cookie_accept');

	if (cookieNotification) {
		if (!cookieDate || (+cookieDate + 31536000000) < Date.now()) {
			cookieNotification.classList.add('show');
		}
		cookieBtn.addEventListener('click', () => {
			localStorage.setItem('cookieDate', Date.now());
			cookieNotification.classList.remove('show');
		})
	}

}

export default CheckCookies;