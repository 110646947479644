import Noty from 'noty';
import { LoadContent } from "../../Services/LoadContent";
import { checkTotal, getURL } from "./Services";

const CartUpdate = () => {

  const container = document.querySelector('#header_cart_canvas');

  const handleCartProductRemoveClick = event => {
    const target = event.target;
    if (target.classList.contains('quantity-cart-btn')) {

			if (target.classList.contains('quantity__minus')) {
				target.setAttribute('data-quantity', parseInt(target.getAttribute('data-quantity')) - 1);
			} else {
				target.setAttribute('data-quantity', parseInt(target.getAttribute('data-quantity')) + 1);
			}

      const formData = new URLSearchParams();
      formData.append('key', target.getAttribute('data-cart_id'));
      formData.append('quantity', target.getAttribute('data-quantity'));

      fetch('index.php?route=checkout/cart/updateQuantity', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Ошибка на сервере: ' + response.statusText);
          }
        })
        .then(json => {
					if (json['success']) {
						
						if (getURL('route') == 'checkout/simplecheckout') {
							location = 'index.php?route=checkout/cart';
						} else {
							LoadContent({
								parentSelector: '#header_cart_canvas',
								url: 'index.php?route=common/cart/info',
								elementSelector: '.offcanvas-body, .cart-canvas-bottom-wrapper, .offcanvas-header',
								cleanParent: true,
							});
							document.querySelectorAll('.cart-total-num').forEach(e => e.textContent = json['total']);
							checkTotal('.cart-total-num');
						}

					}
        })
        .catch(error => {
          new Noty({
						theme: 'relax',
						type: 'error',
						timeout: 3500,
						text: error.message,
					}).show();
          console.error(error);
        });

    }
  };

  container?.addEventListener('click', handleCartProductRemoveClick);

}

export default CartUpdate;