const OptionChange = () => {

	const optionsWrappers = document.querySelectorAll('.product-option-wr'),
		configItems = document.querySelectorAll('.product-config-value');

	optionsWrappers?.forEach(optionsWrapper => {
		const options = optionsWrapper.querySelectorAll('.product-option-value-wr');

		let multiOptions = Array.from(options)
			.filter(e => e.querySelector('input').getAttribute('data-multi_modules') === '1' && e.classList.contains('active'))
			.map(e => ({
				name: e.querySelector('input').getAttribute('data-option'),
				total: 1
			}));

		options?.forEach(e => {
			e.addEventListener('click', event => {
				const wrapper = event.currentTarget,
					option = wrapper.querySelector('input'),
					clothCategoryPrices = document.querySelectorAll('.product-cloth-category-price'),
					priceElements = document.querySelectorAll('.product-price'),
					priceOldElements = document.querySelectorAll('.product-price-old'),
					quantityPlus = wrapper.querySelector('.product-option-quantity-plus'),
					quantityMinus = wrapper.querySelector('.product-option-quantity-minus'),
					productId = document.querySelector('#product-product').getAttribute('data-product_id');

				if (event.target != quantityPlus && event.target != quantityMinus) {

					if (!wrapper.closest('.option_modules')) {
						options?.forEach(e => {
							e.classList.remove('active');
							e.querySelector('input').checked = false;
						});

						wrapper.classList.add('active');
						option.checked = true;
					} else {

						const activeWrappers = Array.from(options).filter(e => e.classList.contains('active'));
						if (activeWrappers.length === 1 && wrapper.classList.contains('active')) {
							return;
						}

						wrapper.classList.toggle('active');
						option.checked = true;
					}

				}

				if (clothCategoryPrices) {

					const optionId = option.getAttribute('data-global_option_id'),
						optionValue = option.getAttribute('data-option'),
						changePrice = option.getAttribute('data-change_price'),
						total = wrapper.querySelector('.product-option-total');

					if (option.getAttribute('data-multi_modules') == '1') {
						if (!multiOptions.some(item => item.name === optionValue)) {
							multiOptions.push({ name: optionValue, total: 1 });
						} else {

							const existingOption = multiOptions.find(item => item.name === optionValue);
							if (event.target === quantityPlus) {
								existingOption.total++;
								total.textContent = existingOption.total;
							} else if (event.target === quantityMinus) {
								if (existingOption.total > 1) {
									existingOption.total--;
									total.textContent = existingOption.total;
								}
							} else {
								multiOptions = multiOptions.filter(item => item.name !== optionValue);
								total.textContent = 1;
							}

						}
					}



					const dataOptions = {
						product_id: productId,
						option_id: optionId,
						option_value: optionValue,
						change_price: changePrice,
						multi_options: multiOptions
					};

					fetch('/index.php?route=product/product/getPricesByOption', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(dataOptions)
					})
						.then(response => {
							if (response.ok) {
								return response.json();
							} else {
								throw new Error('Ошибка на сервере. ' + response.statusText);
							}
						})
						.then(data => {
							//console.log(data)
							if (!data.error) {

								const material_category_id = document.querySelector('.product-cloth-config-item input').getAttribute('data-category_id');

								priceElements.forEach(e => e.textContent = data.data[`price_${material_category_id || '1'}`].price);

								priceOldElements?.forEach(e => e.textContent = data.data[`price_${material_category_id || '1'}`].price_old);

								clothCategoryPrices.forEach(wrapper => {
									const categoryId = wrapper.getAttribute('data-elementid'),
										price = wrapper.querySelector('.product-category-price'),
										price_old = wrapper.querySelector('.product-category-price-old');

									for (const key in data.data) {
										if (Object.hasOwnProperty.call(data.data, key)) {
											const e = data.data[key];

											if (key.replace(/\D/g, '') == categoryId) {
												price.textContent = e.price;
												if (e.price_old) {
													price_old.textContent = e.price_old;
												}
											}

										}
									}

								});
							} else {
								throw new Error('Ошибка на клиенте. ' + data.error);
							}
						})
						.catch(error => {
							console.error('Ошибка: ', error);
						});
				}

				configItems.forEach(e => {
					if (option.dataset.option_id == e.dataset.option_id) {
						if (option.getAttribute('data-multi_modules') == '1') {
							e.textContent = multiOptions.map(({ name, total }) => `${name}(${total})`).join(' + ');
							e.setAttribute('data-option_values', multiOptions.map(({ name, total }) => `${name}[${total}]`).join('#'));
						} else {
							e.textContent = option.dataset.option;
						}
						e.dataset.change_price = option.dataset.change_price;
					}

				});



				options.forEach(e => {
					if (e.querySelector('input').getAttribute('data-multi_modules') === '1')
						e.querySelector('input').value = multiOptions.map(({ name, total }) => `${name}(${total})`).join(' + ');
				});


				//console.log(multiOptions);

				// bed sizes
				/* 			if (wrapper.closest('.option_bed_sizes')) {
								const sizeWidth = document.querySelector('.product-content-info-attribute-size-wr[data-group_id="108"] .product-content-info-attribute-size-width');
								const sleepPlaceLength = document.querySelector('.product-content-info-attribute-size-wr[data-group_id="109"] .product-content-info-attribute-size-width');
				
								switch (option.dataset.option) {
									case '1800x2000 мм':
										sizeWidth.textContent = '1960 мм';
										sleepPlaceLength.textContent = '1800 мм';
										break;
									case '1400x2000 мм':
										sizeWidth.textContent = '1560 мм';
										sleepPlaceLength.textContent = '1400 мм';
										break;
									case '2000x2000 мм':
										sizeWidth.textContent = '2160 мм';
										sleepPlaceLength.textContent = '2000 мм';
										break;
									default:
										sizeWidth.textContent = '1760 мм';
										sleepPlaceLength.textContent = '1600 мм';
										break;
								}
							}
				
							console.log(multiOptions); */

			});
		});
	});

	/* 	configItems.forEach(e => {
			e.addEventListener('click', () => {
				e.classList.toggle('full-width');
			});
		}); */

}

export default OptionChange;