import Swiper from 'swiper';
import { Navigation, Scrollbar} from 'swiper/modules';

const WeekSelection = () => {
		const weekSelectionSlider = new Swiper(".weekSelectionSlider", {
			modules: [Navigation, Scrollbar],
			slidesPerView: 1,
			speed: 800,
			centeredSlides: true,
			spaceBetween: 30,
			grabCursor: true,
			rewind: true,
			initialSlide: 1,
			navigation: {
				nextEl: '.swiper-button-next.week-selection-arrow',
				prevEl: '.swiper-button-prev.week-selection-arrow'
			},
			scrollbar: {
				el: '.swiper-scrollbar.week-selection-scrollbar',
				draggable: true,
			},
			breakpoints: {
				1200: {
					slidesPerView: 3,
					speed: 1700,
				}
			}
		});
}

export default WeekSelection;