import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const DesktopMenu = () => {

	// Stocks slider
	const megaMenuStockSlider = new Swiper(".megaMenuStockSlider", {
		modules: [Navigation],
		lazy: true,
		slidesPerView: 3,
		speed: 800,
		centeredSlides: false,
		spaceBetween: 45,
		grabCursor: true,
		rewind: true,
		navigation: {
			nextEl: ".swiper-button-next.mega-menu-sl-arrow",
			prevEl: ".swiper-button-prev.mega-menu-sl-arrow",
		},
		breakpoints: {
			1580: {
				slidesPerView: 5,
			},
			1400: {
				slidesPerView: 4,
			},
		}
	});


}

export default DesktopMenu;