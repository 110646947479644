const getURL = (key) => {
	const value = [];
	const query = String(document.location).split('?');

	if (query[1]) {
		const part = query[1].split('&');

		for (let i = 0; i < part.length; i++) {
			const data = part[i].split('=');

			if (data[0] && data[1]) {
				value[data[0]] = data[1];
			}
		}

		if (value[key]) {
			return value[key];
		} else {
			return '';
		}
	}
}

const checkTotal = (totalSelector) => {
	document.querySelectorAll(totalSelector).forEach(e => {
		if (e.textContent == '0') {
			e.classList.add('empty');
		} else {
			e.classList.remove('empty');
		}
	});
}

export { getURL, checkTotal }