const AjaxSearch = function () {

			const live_search = {
					selector: ".search__input_header",
					text_view_all_results: 'Показать больше',
					module_live_search_min_length: 2
			};
			const html = '<div class="ajax-search-block"><div class="ajax-search-block-wr"><div class="ajax-search-block-items-wr"><div class="ajax-search-products-wr custom-scrollbar"><div class="ajax-search-products-block modal-search-items"></div></div></div><div class="ajax-search-view-all"></div></div></div>';

			const mobileContainer = document.querySelector('.modal-search-body'),
						searchLocal = JSON.parse(localStorage.getItem('searchHistory')) || [];

			if (window.innerWidth <= 992) {
				mobileContainer.insertAdjacentHTML('afterend', html);
			} else {
				document.querySelector(live_search.selector).insertAdjacentHTML('afterend', html);
			}

			const searchBtns = document.querySelectorAll('.search-action-btn'),
						searchBlock = document.querySelector('.ajax-search-block'),
						productsBlock = document.querySelector('.ajax-search-products-block'),
						viewAll = document.querySelector('.ajax-search-view-all');
			
			document.querySelectorAll(live_search.selector).forEach(e => {
				e.addEventListener('input', function(event) {
						const filter_name = this.value,
									live_search_min_length = live_search.module_live_search_min_length;

						let cat_id = 0;

						e.setCustomValidity('');
						if (filter_name.length < live_search_min_length) {
							searchBlock.style.display = 'none';
						} else {
							let live_search_href = 'index.php?route=extension/module/live_search&filter_name=',
									all_search_href = 'index.php?route=product/search&search=';
							if (cat_id > 0) {
								live_search_href = live_search_href + encodeURIComponent(filter_name) + '&cat_id=' + Math.abs(cat_id);
								all_search_href = all_search_href + encodeURIComponent(filter_name) + '&category_id=' + Math.abs(cat_id);
							} else {
								live_search_href = live_search_href + encodeURIComponent(filter_name);
								all_search_href = all_search_href + encodeURIComponent(filter_name);
							}

								const html = '<svg class="ajax-search-spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>';

								productsBlock.innerHTML = html;
								searchBlock.style.display = 'flex';

								fetch(live_search_href)
										.then(response => response.json())
										.then(result => {
												const products = result.products;

												productsBlock.innerHTML = '';
												viewAll.innerHTML = '';

												if (document.querySelector('.ajax-search-info')) document.querySelector('.ajax-search-info').remove();
												
												if (products && Object.keys(products).length !== 0) {

														const viewAllResultsLink = document.createElement('a');
														viewAllResultsLink.href = all_search_href;
														viewAllResultsLink.className = 'btn btn-default btn-pink ajax-search-view-all-send search-link-btn';
														viewAllResultsLink.textContent = live_search.text_view_all_results;
														viewAll.appendChild(viewAllResultsLink);
												/* 		document.querySelector('.ajax-search-view-all-send').insertAdjacentHTML('beforeend', `<span class="badge text-bg-secondary">${result.products.length}</span>`); */

														searchBtns.forEach(e => {e.setAttribute('href', all_search_href)});


														document.querySelector('.ajax-search-block-items-wr').insertAdjacentHTML('beforeend', '<div class="ajax-search-info"><div class="ajax-search-history-wr"><div class="title--">История поиска<span tabindex="0" data-bs-toggle="tooltip" data-bs-title="Очистить историю" class="icon-delete history-btn-delete"></span></div><ul class="ajax-search-histories custom-scrollbar"></ul></div></div>');

														products.forEach(product => {
																const item = document.createElement('a');
																item.className = 'ajax-search-product';
																item.href = product.url;
																let html = '';

																if (product.image) {
																		html += `<img src="${product.image}" alt="${product.name}" title="${product.name}">`;
																}

																html += `<div class="ajax-search-pr-text">
																		<h4>${product.name}</h4>
																		<div>${product.model}</div>
																</div>`;

																item.innerHTML = html;
																productsBlock.appendChild(item);
														});


														// history add
														if (searchLocal.length > 0) {
															const container = document.querySelector('.ajax-search-histories');

															if (container) {
																container.innerHTML = '';
																searchLocal.forEach(e => {
																	const item = document.createElement('li');
																	item.textContent = e;
																	container.appendChild(item);
																});
															}

															document.querySelector('.history-btn-delete').addEventListener('click', () => {
																localStorage.removeItem("searchHistory");
																container.innerHTML = '';
															});
														}


														// history save
														
														document.querySelectorAll('.search-link-btn').forEach(e => {
															e.addEventListener('click', (event) => {
																let history = [];
																const href = new URL(event.currentTarget.href);
																const param = href.searchParams.get('search');
											
																if (param) {
																	if (searchLocal.length > 0) {
																		history.push(...searchLocal, param);
																	} else {
																		history.push(param);
																	}
												
																	localStorage.setItem('searchHistory', JSON.stringify(history));
																}
															});
														});

												} else {
													//	Text empty
														let html = '<div class="ajax-search-empty">По вашему запросу ничего не найдено.</div>';

														productsBlock.innerHTML = html;

														searchBtns.forEach(e => {
															e.setAttribute('href', '');
														});
														
												}
												searchBlock.style.display = 'flex';
										})
										.catch(error => {
												console.error('Error:', error);
										});

						}
				});
			});


			document.addEventListener("mouseup", e => {
					if (!searchBlock.contains(e.target) && !document.querySelector(live_search.selector).contains(e.target) && !document.querySelector('.modal-search .search__input_header').contains(e.target)) {
							searchBlock.style.display = 'none';
					}
			});


			(() => {
				document.querySelector(live_search.selector).value = '';
				document.addEventListener('click', (event) => {
					const target = event.target.closest('.search-header-btn');
					if (!target) return;
			
					event.preventDefault();
					const href = target.getAttribute('href');
					const input = document.querySelector(live_search.selector);
			
					if (href && href !== '') {
						input.setCustomValidity('');
						window.location.href = href;
					} else {
						input.setCustomValidity('Результаты не найдены. Попробуйте другой запрос.');
						input.reportValidity();
					}
				});
			})();



			// clear old history
			(() => {
				const TWO_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000; // Two days in milliseconds
				const lastClearedTimestamp = localStorage.getItem('searchHistoryLastCleared');
				const currentTimestamp = Date.now();

				if (!lastClearedTimestamp || currentTimestamp - lastClearedTimestamp > TWO_DAYS_IN_MS) {
					localStorage.removeItem('searchHistory');
					localStorage.setItem('searchHistoryLastCleared', currentTimestamp);
				}
			})();

}

export default AjaxSearch;
