class LoadingElements {

	onChildrenLoadingPlaceholder = (parentSelector, childrenSelector, switchOn) => {
		const parent = document.querySelector(parentSelector);
			parent.querySelectorAll(childrenSelector).forEach(e => {
				if (switchOn) {
					e.classList.add('placeholder');
				} else {
					e.classList.remove('placeholder');
				}
			});
	}

	

}

export default LoadingElements;