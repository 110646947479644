const CatalogProductCard = () => {

	const products = document.querySelectorAll('.catalog-products-wrapper .row > div'),
				btnMin = document.querySelector('.catalog-layout-mode-min'),
				btnMax = document.querySelector('.catalog-layout-mode-max');
 
	if (btnMax && btnMin) {
		btnMax.addEventListener('click', (event) => {
			products.forEach(e => e.classList.remove('catalog-product-item-full'));
			event.currentTarget.classList.add('active');
			btnMin.classList.remove('active');
		});
	
		btnMin.addEventListener('click', (event) => {
				products.forEach(e => e.classList.add('catalog-product-item-full'));
				event.currentTarget.classList.add('active');
				btnMax.classList.remove('active');
		});
	}

	const btnsOpenBottomBlock = document.querySelectorAll('.product-card-open-bottom-block');

	const removeAllOpenClass = () => {
		document.querySelectorAll('.product-card-bottom-block')?.forEach(e => e.classList.remove('open'));
		btnsOpenBottomBlock?.forEach(e => e.classList.remove('open'));
	}

	btnsOpenBottomBlock?.forEach(e => {
		e.addEventListener('click', event => {
			if (event.currentTarget.classList.contains('open')) {
				event.currentTarget.classList.remove('open');
				event.currentTarget.nextElementSibling.classList.remove('open');
			} else {
				removeAllOpenClass();
				event.currentTarget.classList.add('open');
				event.currentTarget.nextElementSibling.classList.add('open');
			}

		});
	});

}

export default CatalogProductCard;